import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Icon, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import {
  useDeleteMembersMutation,
  useGetAllMembersMutation,
} from "../../features/beneficiary/beneficiaryApiSlice";
import {
  setMembers,
  setSelectedMember,
} from "../../features/beneficiary/beneficiarySlice";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import SendNotification from "../common/SendNotification";
import Spinner from "../common/Spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "0",
  borderRadius: "5px",
  boxShadow: 24,
  overflow: "scroll",
  pt: 2,
  px: 4,
  pb: 3,
  m: "20px 0",
};

const MembersTable = ({
  handleShowMemberDetails,
  BeneficiaryMenuNavigator,
}) => {
  const [selectionModel, setSelectionModel] = React.useState([]); // holds all the selected post
  const [openModal, setOpenModal] = React.useState(false);
  const [sendNotificationForAllMembers, setSendNotificationForAllMembers] =
    React.useState(false);

  const dispatch = useDispatch();

  const [getAllMembers] = useGetAllMembersMutation();
  const [deleteMembers] = useDeleteMembersMutation();

  useEffect(() => {
    fetchAllMembers();
  }, []);

  // get all the members from the server who are not trashed
  const fetchAllMembers = async () => {
    const members = await getAllMembers().unwrap();
    console.log(members);
    if (members.status === 500) {
      toast.error("Internal Server Erorr");
      return;
    }
    dispatch(setMembers(members.data));
  };

  const membersList = useSelector((state) => state.beneficiary.members);

  const deleteBeneficiaryMembers = async (memberIds) => {
    try {
      let membersToDelete = {
        members_id: memberIds,
      };
      const result = await deleteMembers(membersToDelete).unwrap();
      toast.success(
        "Successfully deleted members"
      );
      // clearing the state
      setSelectionModel([]);
      return "success";
    } catch (err) {
      console.log(err);
      if (err.status === 404) {
        toast.error("Please select atleast one member");
        return "error";
      }
    }
  };

  const columns = [
    { field: "ycf_id", headerName: "YCF ID" },
    {
      field: "profile_photo",
      headerName: "Photo",
      sortable: false,
      filterable: false,
      renderCell: (param, index) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <img src={param.value} style={{ height: "100%" }} />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth: 200,
      // sortable: false,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "aadhaar_number",
      headerName: "Aadhaar Number",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "education_amount",
      headerName: "Education Amount",
      flex: 1,
      minWidth: 165,
      valueGetter: (params) =>
      params.row.member_benefits[0]?.education_amount
      ,
      renderCell: (params) =>
        params.row.member_benefits[0]?.education_amount
       ,
    },
    {
      field: "education_frequency",
      headerName: "Education Frequency",
      flex: 1,
      minWidth: 165,
      valueGetter: (params) =>
      params.row.member_benefits[0]?.education_frequency
      ,
      renderCell: (params) =>
        params.row.member_benefits[0]?.education_frequency
       ,
    },
    {
      field: "ration_amount",
      headerName: "Ration Amount",
      flex: 1,
      minWidth: 165,
      valueGetter: (params) =>
      params.row.member_benefits[0]?.ration_amount
      ,
      renderCell: (params) =>
        params.row.member_benefits[0]?.ration_amount
       ,
    },
    {
      field: "ration_frequency",
      headerName: "Ration Frequency",
      flex: 1,
      minWidth: 165,
      valueGetter: (params) =>
      params.row.member_benefits[0]?.ration_frequency
      ,
      renderCell: (params) =>
        params.row.member_benefits[0]?.ration_frequency
       ,
    },
    {
      field: "travel_amount",
      headerName: "Travel Amount",
      flex: 1,
      minWidth: 165,
      valueGetter: (params) =>
      params.row.member_benefits[0]?.travel_amount
      ,
      renderCell: (params) =>
        params.row.member_benefits[0]?.travel_amount
       ,
    },
    {
      field: "travel_frequency",
      headerName: "Travel Frequency",
      flex: 1,
      minWidth: 165,
      valueGetter: (params) =>
      params.row.member_benefits[0]?.travel_frequency
      ,
      renderCell: (params) =>
        params.row.member_benefits[0]?.travel_frequency
       ,
    },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 180,
      filterable: false,
      sortable:false,
      renderCell: (param) => (
        <Box width="100%">
          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              dispatch(setSelectedMember(param.row));
              BeneficiaryMenuNavigator("showMemberDetails");
            }}
          >
            <VisibilityIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>

          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              setSendNotificationForAllMembers(false);
              setSelectionModel([param.row.id]);
              setOpenModal(true);
            }}
          >
            <ChatBubbleIcon />
          </IconButton>

          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              dispatch(setSelectedMember(param.row));
              BeneficiaryMenuNavigator("addMember");
            }}
          >
            <EditIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
          <IconButton
            sx={{ width: "40px" }}
            onClick={async () => {
              const selectedIDs = new Set([param.row.id]);
              let filteredMembers = membersList.filter(
                (x) => !selectedIDs.has(x.id)
              );
              const result = await deleteBeneficiaryMembers([param.row.id]);
              if (result !== "error") {
                dispatch(setMembers(filteredMembers));
                return;
              }
              return;
            }}
          >
            <DeleteIcon
              sx={{
                width: "20px",
                color: "#ba000d",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <SendNotificationToAll
          setOpenModal={setOpenModal}
          setSendNotificationForAllMembers={setSendNotificationForAllMembers}
        />
        {/* When user selects some data then only display it */}
        {selectionModel.length > 0 && (
          <>
            <SendMultipleMessage
              setOpenModal={setOpenModal}
              setSendNotificationForAllMembers={
                setSendNotificationForAllMembers
              }
            />
            <DeleteAllButton
              selectionModel={selectionModel}
              deleteBeneficiaryMembers={deleteBeneficiaryMembers}
              membersList={membersList}
              dispatch={dispatch}
            />
          </>
        )}
      </GridToolbarContainer>
    );
  }

  // Close Modal
  const handleModalClose = () => {
    setSendNotificationForAllMembers(false);
    setOpenModal(false);
    setSelectionModel("");
  };

  return (
    <>
      {/*? (*/}
      <Box>
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "60px",
              }}
            >
              <Typography variant="h5">Send Notification</Typography>
              <Button variant="contained" onClick={handleModalClose}>
                Close
              </Button>
            </Box>
            <SendNotification
              setOpenModal={setOpenModal}
              sendToAll={sendNotificationForAllMembers}
              memberIds={selectionModel}
            />
          </Box>
        </Modal>
      </Box>
      {membersList ?
       <Box
       sx={{
         height: "80vh",
         backgroundColor: "#fff",
         borderRadius: "5px",
       }}
     >
       <DataGrid
         rows={membersList}
         columns={columns}
         checkboxSelection
         disableSelectionOnClick
         density="compact"
         components={{ Toolbar: CustomToolbar }}
         slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
         onSelectionModelChange={(ids) => {
           setSelectionModel(ids);
         }}
       />
     </Box>
     :
     <Box sx={{
      display: "flex",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) scale(2)",
    }}>
      <Spinner />
    </Box>
    }
     
    </>
  );
};

export default MembersTable;

const DeleteAllButton = ({
  selectionModel,
  deleteBeneficiaryMembers,
  membersList,
  dispatch,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: "10px",
      }}
    >
      <IconButton
        onClick={async () => {
          if (selectionModel.length === 0) {
            toast.error("Please select atleast 1 post to delete");
            return;
          }
          const selectedIDs = new Set(selectionModel);
          let filteredMembers = membersList.filter(
            (x) => !selectedIDs.has(x.id)
          );
          const result = await deleteBeneficiaryMembers(selectionModel);
          if (result != "error") {
            dispatch(setMembers(filteredMembers));
            return;
          }
          return;
        }}
      >
        <DeleteIcon color="primary" />
      </IconButton>
    </Box>
  );
};

const SendMultipleMessage = ({
  setOpenModal,
  setSendNotificationForAllMembers,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: "40px",
      }}
    >
      <IconButton
        sx={{ width: "40px" }}
        onClick={() => {
          setSendNotificationForAllMembers(false);
          setOpenModal(true);
        }}
      >
        <ChatBubbleIcon />
      </IconButton>
    </Box>
  );
};

const SendNotificationToAll = ({
  setOpenModal,
  setSendNotificationForAllMembers,
}) => {
  return (
    <Box
      sx={
        {
          // position: "absolute",
          // right: "40px",
        }
      }
    >
      <Button
        onClick={() => {
          setOpenModal(true);
          setSendNotificationForAllMembers(true);
        }}
        size="small"
      >
        <ChatBubbleIcon sx={{ marginRight: "5px" }} />
        Send Notification To All
      </Button>
    </Box>
  );
};
