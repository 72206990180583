import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logout } from "../../../features/auth/authSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { SERVERLINK } from "../../../config/config";

// const baseUrl = 'http://localhost:8080';

const baseQuery = fetchBaseQuery({
    baseUrl: SERVERLINK,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.userToken
        if(token) {
            headers.set("x-access-token", token);
        }
        return headers;
    }
})

const baseQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    console.log("Resp ", result);
    if(result?.error?.status === 401){
        const user = api.getState().auth.userInfo;
        try{
            const refreshResult = await axios.post(SERVERLINK + "/api/auth/dashboard_refresh_token", {
                email: user.email,
                refresh_token: user.refresh_token
            })
            if(refreshResult?.data){
                // store the new token
                let payload = {userData: user, token: refreshResult.data.access_token}
                localStorage.setItem('userToken', refreshResult.data.access_token);
                api.dispatch(setCredentials(payload))
                // retry the original query
                result = await baseQuery(args, api, extraOptions);
            } else {
                api.dispatch(logout());
                toast.error("You have been logged out")
            }
        } catch(err) {
            api.dispatch(logout());
            toast.error("You have been logged out")
        }
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReAuth,
    endpoints: builder => ({})
})