import React from "react";
import { Typography, Grid, Modal, Button } from "@mui/material";
import Box from "@mui/material/Box";
import ApplicantDetail from "./ApplicantDetail";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { applicationFormField } from "../../constants/applicationFormFields";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUpdateApplicationFormMutation } from "../../features/applicationForm/applicationFormApiSlice";
import { toast } from "react-toastify";

const style = {
  width: "45vw",
  // height: "90vh",
  height: "80vh",
  bgcolor: "background.paper",
  overflowX: "scroll",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  m: "20px 0",
};

const ViewApplication = ({
  openViewModal,
  setOpenViewModal,
  selectedApplication,
  applicationData,
  setApplicationData,
  requestType = "PENDING",
}) => {
  const isMobile = useMediaQuery("(max-width:700px)");
  const [updateApplicationForm] = useUpdateApplicationFormMutation();

  const handleUpdateApplication = async (status) => {
    try {
      await updateApplicationForm({
        id: selectedApplication.id,
        status: status,
      }).unwrap();

      const updatedApplicationList = applicationData.filter(
        (element) => element.id !== selectedApplication.id
      );

      setApplicationData(updatedApplicationList);
      setOpenViewModal(false);
      toast.success("Updated application status");
    } catch (error) {
      console.log(error);
      toast.error(
        error.data.message ||
          `Some unknow error occured to ${status} application`
      );
    }
  };

  return (
    <>
      <Modal
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style} style={{ width: isMobile && "95%" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Application Form
          </Typography>
          {console.log("Applicatopn ", selectedApplication)}
          {selectedApplication && (
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    {Object.keys(selectedApplication)?.map((key) => {
                      if (key !== "benefits" && key !== "benefit_frequency" && key !== "children")
                        return (
                          key !== "updated_at" && (
                            <Grid
                              item
                              md={6}
                              xs={12}
                              sx={{ padding: "0 6px", display: "flex" }}
                            >
                              <ApplicantDetail
                                heading={applicationFormField[key]}
                                text={
                                  key === "date_of_birth"
                                    ? new Date(
                                        selectedApplication[key]
                                      ).toLocaleDateString()
                                    : key === "created_at"
                                    ? new Date(
                                        selectedApplication[key]
                                      ).toLocaleDateString()
                                    : selectedApplication[key]
                                }
                              />
                            </Grid>
                          )
                        );
                      return null;
                    })}
                  </Grid>
                </Grid>
                {selectedApplication?.children?.length !== 0 &&
                <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell align="right">Child Name</TableCell>
                        <TableCell align="right">College/Institution</TableCell>
                        <TableCell align="right">Standard/Grade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedApplication?.children &&
                        selectedApplication?.children.map(
                          (child, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {index}
                                </TableCell>
                                <TableCell align="right">{child?.child_name}</TableCell>
                                <TableCell align="right">
                                  {child?.school_college_name}
                                </TableCell>
                                <TableCell align="right">
                                  {child?.standard_grade}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
                
                }
                
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell align="right">Benefit</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Freqeuncy</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedApplication?.benefits &&
                          Object.keys(selectedApplication?.benefits).map(
                            (key, index) => {
                              console.log(key);
                              return (
                                <TableRow
                                  key={key}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {index}
                                  </TableCell>
                                  <TableCell align="right">{key}</TableCell>
                                  <TableCell align="right">
                                    {selectedApplication?.benefits[key]}
                                  </TableCell>
                                  <TableCell align="right">
                                    {
                                      selectedApplication?.benefit_frequency[
                                        key
                                      ]
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {requestType === "PENDING" && (
                <>
                  {" "}
                  <hr style={{ marginTop: "30px" }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      left: 0,
                      marginTop: 1,
                    }}
                  >
                    <Button onClick={() => handleUpdateApplication("reject")}>
                      Reject
                    </Button>
                    <Button
                      onClick={() => handleUpdateApplication("accept")}
                      variant="contained"
                    >
                      Accept
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ViewApplication;
