import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { useUpdateMemberPasswordMutation } from "../../features/beneficiary/beneficiaryApiSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0",
  borderRadius: "5px",
  boxShadow: 24,
  overflow: "scroll",
  p: 2,
  m: "20px 0",
};

const ChangeMemberPassword = ({ setOpenChangePassword }) => {

  const { selectedMembers } = useSelector((state) => state.beneficiary);
  const [updateMemberPassword] = useUpdateMemberPasswordMutation();

  const [passwordChange, setPasswordChange] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    samePasswordError: "",
    newPasswordError: "",
  });

  const handleValueChange = (e) => {
    setError({
      samePasswordError: "",
      newPasswordError: "",
    });

    setPasswordChange((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async () => {
    if (passwordChange.newPassword.length < 8) {
      setError((prevValue) => {
        return {
          ...prevValue,
          newPasswordError: "please enter 8 character of longer",
        };
      });

      return;
    }

    // Check if the entered password is same
    if (passwordChange.newPassword !== passwordChange.confirmPassword) {
      setError((prevValue) => {
        return {
          ...prevValue,
          samePasswordError: "Please enter same password in both fields",
        };
      });
      return;
    }

    // on validation pass
    try {
      let passwordChangeObject = {
        member_id: selectedMembers.id,
        new_password: passwordChange.newPassword
      }
      let result = await updateMemberPassword(passwordChangeObject).unwrap();
      toast.success("Successfully changed password")
      setOpenChangePassword(false);
    } catch (error) {
      console.log(error);
      toast.error("There was some error updating password")
    }

  };

  return (
    <Box sx={style}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ m: "0 0 -2px 0" }}>
              Member Password
            </Typography>
            <Typography
              variant="p"
              paragraph
              fontSize={13}
              color="grey"
              sx={{ m: "0 0 -10px 0" }}
            >
              Change member password
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "20px" }}>
            <TextField
              id="newPassword"
              label="New Password"
              variant="outlined"
              name="newPassword"
              size="small"
              fullWidth
              onChange={handleValueChange}
              value={passwordChange.newPassword}
            />
            <p className="error-text">{error.newPasswordError}</p>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "20px" }}>
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              variant="outlined"
              name="confirmPassword"
              size="small"
              fullWidth
              onChange={handleValueChange}
              value={passwordChange.confirmPassword}
            />
            <p className="error-text">{error.samePasswordError}</p>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit" onClick={handleSubmit}>
              Change Password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChangeMemberPassword;
