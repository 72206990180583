import React, { useEffect } from "react";
import {
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Card
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Webcam from "react-webcam";
import DummyPhoto from "../../assets/images/photo-dummy.jpeg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { uploadMemberProfilePhoto } from "../../api/awsS3.api";
import {
  useAddMemberMutation,
  useDeleteChildMutation,
  useUpdateMemberMutation,
} from "../../features/beneficiary/beneficiaryApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSelectedMember } from "../../features/beneficiary/beneficiarySlice";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const WebcamComponent = () => <Webcam />;

const NUMBERREGEX = new RegExp(
  /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
);

const memberSchema = Yup.object().shape({
  fullName: Yup.string().min(2, "Too Short!").required("Required"),
  ycfid: Yup.string().min(2, "Too Short!").required("Required"),
  mobileNumber: Yup.string()
    .min(10, "Too Short!")
    .max(12, "Too large!")
    .required("Required")
    .matches(NUMBERREGEX, "Only numbers are allowed"),
  alternateMobileNumber: Yup.string()
    .min(10, "Too Short!")
    .max(12, "Too large!")
    .matches(NUMBERREGEX, "Only numbers are allowed"),
  aadhaarNumber: Yup.string()
    .min(12, "Too Short!")
    .max(12, "Too Large")
    .required("Required")
    .matches(NUMBERREGEX, "Only numbers are allowed"),
  panCardNumber: Yup.string()
    .min(10, "Too Short!")
    .max(10, "Too Large")
    .required("Required"),
  address: Yup.string().min(2, "Too Short!").required("Required"),
  pincode: Yup.string()
    .min(2, "Too Short!")
    .required("Required")
    .matches(NUMBERREGEX, "Only numbers are allowed"),
  bankName: Yup.string().min(2, "Too Short!").required("Required"),
  bankAccountNumber: Yup.string()
    .min(2, "Too Short!")
    .required("Required")
    .matches(NUMBERREGEX, "Only numbers are allowed"),
  ifscCode: Yup.string().min(2, "Too Short!").required("Required"),
  bankBranchName: Yup.string().min(2, "Too Short!").required("Required"),
  yearlyQuota: Yup.string(),
  dob: Yup.string().required("Required"),
  salary: Yup.string().required("Required"),
  disabled: Yup.string().required("Required"),
  registeredFilmUnionMember: Yup.string().required("Required"),
  activeSaathiMemberBefore2022: Yup.string().required("Required"),
  retired: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
});

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

let module = {
  education: "EDUCATION",
  medical: "HEALTH",
  houseHoldVoucher: "HOUSEHOLD",
};

const initialValues = {
  fullName: "",
  ycfid: "",
  mobileNumber: "",
  alternateMobileNumber: "",
  aadhaarNumber: "",
  panCardNumber: "",
  address: "",
  pincode: "",
  bankName: "",
  bankAccountNumber: "",
  ifscCode: "",
  bankBranchName: "",
  yearlyQuota: "",
  gender: "",
  dob: "",
  registeredFilmUnionMember: "",
  activeSaathiMemberBefore2022: "",
  disabled: "",
  mentionDisability: "",
  salary: "",
  retired: "",
};

const AddMember = ({ BeneficiaryMenuNavigator }) => {
  const [value, setValue] = React.useState(dayjs(new Date()));

  const dispatch = useDispatch();

  const [addMember] = useAddMemberMutation();
  const [updateMember] = useUpdateMemberMutation();
  const [deleteChild] = useDeleteChildMutation();

  const { selectedMembers } = useSelector((state) => state.beneficiary);

  const [captureImage, setCaptureImage] = React.useState(null);
  const [imageCaptured, setImageCaptured] = React.useState(false);
  const [moduleCheckboxes, setModuleCheckboxes] = React.useState({
    medical: true,
    education: selectedMembers?.modules?.includes("EDUCATION") ? true : false,
    houseHoldVoucher: selectedMembers?.modules?.includes("HOUSEHOLD")
      ? true
      : false,
  });
  const [memberModuleSelected, setMemberModuleSelected] = React.useState();

  // New Additions
  const [totalSelectedBenefitAmount, setTotalSelectedbenefitAmount] = React.useState(0)
  console.log("Selecrted" , selectedMembers?.member_benefits[0].education_amount)
  const [benefits, setBenefit] = React.useState({
    education: selectedMembers ? selectedMembers?.member_benefits[0]?.education_amount : 0,
    ration: selectedMembers  ? selectedMembers?.member_benefits[0]?.ration_amount : 0,
    travel: selectedMembers ? selectedMembers?.member_benefits[0]?.travel_amount : 0
  })

  const [benefitFequency, setBenefitFrequency] = React.useState({
    education: selectedMembers ? selectedMembers?.member_benefits[0]?.education_frequency : "",
    ration: selectedMembers ? selectedMembers?.member_benefits[0]?.ration_frequency : "",
    travel: "yearly"
  })

  const [childrenList, setChildrenList] = React.useState(
    selectedMembers ? selectedMembers.children : []
  );
  const [currentChild, setCurrentChild] = React.useState({
    id: null,
    child_name: "",
    school_college_name: "",
    standard_grade: "",
  });

  const [picture, setPicture] = React.useState(
    selectedMembers ? selectedMembers.profile_photo : null
  );
  const [previewImage, setPreviewImage] = React.useState(
    selectedMembers?.profile_photo
  );
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  initialValues.fullName = selectedMembers ? selectedMembers.full_name : "";
  initialValues.mobileNumber = selectedMembers
    ? selectedMembers.mobile_number
    : "";
  initialValues.aadhaarNumber = selectedMembers
    ? selectedMembers.aadhaar_number
    : "";
  initialValues.panCardNumber = selectedMembers
    ? selectedMembers.pancard_number
    : "";
  initialValues.address = selectedMembers ? selectedMembers.address : "";
  initialValues.pincode = selectedMembers ? selectedMembers.pincode : "";
  
  initialValues.bankName = selectedMembers
    ? selectedMembers.bank_detail[0]?.bank_name
    : "";
  initialValues.bankAccountNumber = selectedMembers
    ? selectedMembers?.bank_detail[0]?.bank_account_number
    : "";
  initialValues.ifscCode = selectedMembers
    ? selectedMembers.bank_detail[0]?.ifsc_code
    : "";
  initialValues.bankBranchName = selectedMembers
    ? selectedMembers.bank_detail[0]?.bank_branch_name
    : "";
  initialValues.yearlyQuota = selectedMembers
    ? selectedMembers?.yearly_quota
    : "";
  initialValues.alternateMobileNumber = selectedMembers
    ? selectedMembers?.alternate_mobile_number
    : "";
  initialValues.dob = selectedMembers ? selectedMembers?.date_of_birth : "";
  initialValues.gender = selectedMembers ? selectedMembers?.gender : "";
  initialValues.registeredFilmUnionMember = setSelectedMember
    ? selectedMembers?.member_other_detail[0]?.registered_member_of_film_union
    : "";
  initialValues.activeSaathiMemberBefore2022 = setSelectedMember
    ? selectedMembers?.member_other_detail[0]?.active_saathi_member_till_2022
    : "";
  initialValues.retired = setSelectedMember
    ? selectedMembers?.member_other_detail[0]?.retired_person
    : "";
  initialValues.disabled = setSelectedMember
    ? selectedMembers?.member_other_detail[0]?.disabled
    : "";
  initialValues.mentionDisability = setSelectedMember
    ? selectedMembers?.member_other_detail[0]?.disability
    : "";
  initialValues.salary = setSelectedMember
    ? selectedMembers?.member_other_detail[0]?.monthly_salary_range
    : "";
  initialValues.ycfid = setSelectedMember ? selectedMembers?.ycf_id : "";

  const columns = [
    { field: "child_name", headerName: "Name", flex: 1 },
    { field: "school_college_name", headerName: "School/College", flex: 1 },
    { field: "standard_grade", headerName: "Standard/Grade", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (param) => (
        <IconButton
          sx={{ width: "40px" }}
          onClick={async () => {
            const selectedIDs = new Set([param.row.id]);
            if (selectedMembers) {
              let childToDelete = {
                child_id: param.row.id,
              };
              try {
                let result = await deleteChild(childToDelete).unwrap();
                toast.success("Successfully deleted 1 child");
              } catch (err) {
                console.log(err);
                toast.error(err?.data?.message);
              }
            }

            let filteredChildren = childrenList.filter(
              (x) => !selectedIDs.has(x.id)
            );
            setChildrenList(filteredChildren);
          }}
        >
          <DeleteIcon
            sx={{
              width: "20px",
              color: "#ba000d",
            }}
          />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {

    const totalSelectedAmount = calculateTotalSum(benefits)
    setTotalSelectedbenefitAmount(totalSelectedAmount)
    // if (totalSelectedAmount > totalAmountAllowed) toast.error(`Total selected amount should not be more than ${totalAmountAllowed}`)

  }, [benefits])

  const calculateTotalSum = (totalSelected) => {
    console.log(benefits);
    const amounts = Object.values(totalSelected).map(Number)
    const totalSum = amounts.reduce((sum, amount) => sum + amount, 0)
    return totalSum
  }

  const generateYCFID = (setFieldValue) => {
    let ycf_id = "YCF-";
    var min = 1;
    var max = 99999;
    var ycf_number = Math.floor(Math.random() * (max - min + 1)) + min;
    if (ycf_number.toString().length < 5) {
      let ycf_number_with_zeros = "";
      for (let i = 0; i < 5 - ycf_number.toString().length; i++) {
        ycf_number_with_zeros += "0";
      }
      setFieldValue("ycfid", (ycf_id += ycf_number_with_zeros + ycf_number));
      console.log((ycf_id += ycf_number_with_zeros + ycf_number));
      return (ycf_id += ycf_number_with_zeros + ycf_number);
    } else {
      setFieldValue("ycfid", (ycf_id += ycf_number));
      console.log((ycf_id += ycf_number));
      return (ycf_id += ycf_number);
    }
  };

  const handleCheckboxClick = (e) => {
    // The code below toggles between the household voucher's checkbox and the education checkbox
    let currentSelectedModule = e.target.id;
    if (currentSelectedModule === "education") {
      setModuleCheckboxes((prevValue) => {
        return {
          ...prevValue,
          education: true,
          houseHoldVoucher: false,
        };
      });
    } else {
      setModuleCheckboxes((prevValue) => {
        return {
          ...prevValue,
          education: false,
          houseHoldVoucher: true,
        };
      });
    }
  };

  const handleChildChange = (e) => {
    let currrentInputElementValue = e.target.value;

    // Get children length to update the id
    let childrenCount = childrenList.length;

    setCurrentChild((prevValue) => {
      return {
        ...prevValue,
        id: childrenCount + 1,
        [e.target.id]: currrentInputElementValue,
      };
    });

    console.log(currentChild);
  };

  const handleAddChild = () => {
    console.log("Current" + JSON.stringify(currentChild));

    setChildrenList((prevValue) => {
      return [...prevValue, currentChild];
    });

    // Reset the currentChild state
    setCurrentChild({
      id: null,
      child_name: "",
      school_college_name: "",
      standard_grade: "",
    });
  };

  // To validation
  const handleFormSubmit = async (values, { resetForm }) => {
    // if (childrenList.length == 0) {
    //   toast.error("Please add atleast one child");
    //   return;
    // }

    let checkBoxCount = 0;

    for (let key in moduleCheckboxes) {
      if (moduleCheckboxes[key] === true) {
        checkBoxCount++;
      }
    }

    if (checkBoxCount !== 2) {
      toast.error("Please select atleast two modules");
      return;
    }

    if(totalSelectedBenefitAmount === 0) return toast.error("Please add some benefit amount")
    if(benefitFequency.education === "") return toast.error("Please select frequency for education");
    if(benefitFequency.ration === "") return toast.error("Please select frequency for ration");

    // IF user has selected education module then child is a must
    // console.log(moduleCheckboxes);
    // if (moduleCheckboxes.education === true && childrenList.length === 0) {
    //   console.log("hey");
    //   toast.error("Please add atleast one child for the education module");
    //   return;
    // }

    // if (picture === "") {
    //   toast.error("Please capture image");
    //   return;
    // }

    try {
      let memberObject = values; // value recieved from formik
      // Uplaod image to s3=
      if (picture !== null) {
        if (!picture?.includes(".jpeg")) {
          const base64Response = await fetch(picture);
          const blob = await base64Response.blob();
          // Filename: Fullname with spaces remove + their number + extension
          let fileName =
            values.fullName.replace(/\s/g, "") + uuidv4() + ".jpeg";
          const memberProfilePhoto = new File([blob], fileName, {
            type: "image/jpeg",
          });
          let memberProfilePhotoURL = await uploadMemberProfilePhoto(
            memberProfilePhoto
          );
          memberObject.memberProfile =
            memberProfilePhotoURL?.data?.image_location[0];
        } else {
          memberObject.memberProfile = picture;
        }
      }
      // if there are children then add it to the main list being sent
      // the server
      if (childrenList.length > 0) {
        memberObject.children = childrenList;
      }
      // Add modules for the the member
      let modules = [];
      for (let key in moduleCheckboxes) {
        if (moduleCheckboxes[key]) modules.push(module[key]);
      }

      memberObject.modules = modules;
      memberObject.member_benefits = {
        education_amount: benefits.education,
        travel_amount: benefits.travel,
        ration_amount: benefits.ration,
        education_frequency: benefitFequency.education,
        ration_frequency: benefitFequency.ration,
        travel_frequency: benefitFequency.travel
      }
      // addMember
      let result;
      if (selectedMembers) {
        memberObject.member_id = selectedMembers.id;
        memberObject.bank_id = selectedMembers.bank_detail[0].id;
        memberObject.member_other_details_id = selectedMembers?.member_other_detail[0]?.id;
        memberObject.benefits_id = selectedMembers?.member_benefits[0]?.id;
        
        console.log(JSON.stringify(memberObject));
        result = await updateMember(memberObject).unwrap();
      } else {
        result = await addMember(memberObject).unwrap();
      }

      toast.success("Success");
      // Clear the form

      dispatch(setSelectedMember(null));
      setChildrenList([]);
      setPicture("");
      setPreviewImage(null);
      setCaptureImage(null);
      setImageCaptured(false);
      if (selectedMembers) {
        for (let key in initialValues) {
          initialValues[key] = "";
        }
      }

      initialValues.ycfid = "";
      initialValues.salary = "";
      resetForm();
      BeneficiaryMenuNavigator("members");
    } catch (err) {
      console.log(err);
      console.log("err" + JSON.stringify(err));
      toast.error(err?.data?.message);
    }
  };

  const handleFormChange = (e) => {
    // if (
    //   e.target.name === "rationSelect" ||
    //   e.target.name === "educationSelect" ||
    //   e.target.name === "travelSelect"
    // ) {
    //   setBenefitFrequency((prevValue) => {
    //     return {
    //         ...prevValue,
    //         [e.target.name === "rationSelect" ? "ration" : e.target.name === "educationSelect" ? "education" : "travel"]: e.target.value,
    //     }
    //   })

    //   return
    // }

    // if (
    //   e.target.name === "ration" ||
    //   e.target.name === "education" ||
    //   e.target.name === "travel"
    // ) {

    //   if(e.target.name === "travel" && e.target.value > 2000) return toast.error("Maximum allowed for travel is 2000")

    //   if(e.target.name === "ration") {
    //     // const educationBalance = parseInt(formFields.benefits.education);
    //     // const travelBalance = parseInt(formFields.benefits.travel);
    //     const userSelectedAmount = parseInt(e.target.value);

    //     const MAX_TOTOL_AMOUNT = 12000;
    //     const MAX_ALLOWED_TRAVEL = 2000;

    //     setBenefit(() => {
    //       return {
    //           ration: userSelectedAmount,
    //           education: (MAX_TOTOL_AMOUNT - MAX_ALLOWED_TRAVEL) - userSelectedAmount,
    //           travel: MAX_ALLOWED_TRAVEL
    //       }
    //     })
    //   }

    //   if(e.target.name === "education") {
    //     // const educationBalance = parseInt(formFields.benefits.education);
    //     const travelBalance = parseInt(benefits.travel);
    //     const userSelectedAmount = parseInt(e.target.value);

    //     const MAX_TOTOL_AMOUNT = 12000;
    //     const MAX_ALLOWED_TRAVEL = 2000;

    //     setBenefit(() => {
    //       return {
    //           ration: (MAX_TOTOL_AMOUNT - MAX_ALLOWED_TRAVEL) - userSelectedAmount,
    //           education: userSelectedAmount,
    //           travel: MAX_ALLOWED_TRAVEL
    //       }
    //     })
    //   }

    //   if(e.target.name === "travel") {
    //     const educationBalance = parseInt(benefits.education);
    //     const userSelectedAmount = parseInt(e.target.value);

    //     const MAX_TOTOL_AMOUNT = 12000;

    //     setBenefit(() => {
    //       return {
    //           ration: (MAX_TOTOL_AMOUNT - userSelectedAmount) - userSelectedAmount,
    //           education: userSelectedAmount,
    //           travel: userSelectedAmount  
    //       }
    //     })
    //   }
      

    //   setBenefit((prevValue) => {
    //     return {
    //         ...prevValue,
    //         [e.target.name]: e.target.value,
    //     }
    //   })

    //   return
    // }



    setBenefit((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "5px",
        p: "10px",
      }}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={memberSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ m: "0 0  0" }}>
                    Photo
                  </Typography>
                  <Typography
                    variant="p"
                    paragraph
                    fontSize={13}
                    color="grey"
                    sx={{ m: "0 0 5px 0" }}
                  >
                    Please upload or capture member photo
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Box>
                      {captureImage && (
                        <Webcam
                          audio={false}
                          // height={480}
                          ref={webcamRef}
                          width={400}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                        />
                      )}
                      {imageCaptured && <img src={picture} alt="profile"/>}
                      {captureImage && (
                        <Box display="flex" alignItems="center">
                          <IconButton
                            variant="outlined"
                            onClick={(e) => {
                              e.preventDefault();
                              capture();
                              setCaptureImage(false);
                              setImageCaptured(true);
                            }}
                            className="btn btn-danger"
           >
                            <CameraAltIcon />
                          </IconButton>
                        </Box>
                      )}

                      {imageCaptured && (
                        <Box display="flex" alignItems="center">
                          <IconButton
                            variant="outlined"
                            onClick={(e) => {
                              e.preventDefault();
                              setCaptureImage(true);
                              setImageCaptured(false);
                            }}
                            className="btn btn-primary"
                          >
                            <FlipCameraIosIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    {(captureImage == null || previewImage) &&
                      !selectedMembers && (
                        <img src={DummyPhoto} alt="member" style={{height: "170px"}}/>
                      )}
                    <Box>
                      {previewImage && captureImage == null && (
                        <img src={previewImage} alt="added" width="250px" />
                      )}
                    </Box>
                    <Box sx={{ m: "6px 0 0 0 " }}>
                      <Button
                        variant="outlined"
                        sx={{ m: "0 5px 0 0" }}
                        onClick={() => {
                          setCaptureImage(true);
                          setPreviewImage(null);
                        }}
                      >
                        Capture Image
                      </Button>
                    </Box>
                  </Box>
                </Grid>

                <Box></Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ m: "0 0 -2px 0" }}>
                  Personal Details
                </Typography>
                <Typography
                  variant="p"
                  paragraph
                  fontSize={13}
                  color="grey"
                  sx={{ m: "0 0 -10px 0" }}
                >
                  Please add personal details of the beneficiary
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    id="ycfid"
                    label="YCF ID"
                    variant="outlined"
                    name="ycfid"
                    size="small"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ycfid}
                    error={!!touched.ycfid && !!errors.ycfid}
                    InputLabelProps={{ shrink: true }}
                  />
                  <IconButton
                    onClick={() => generateYCFID(setFieldValue)}
                    sx={{
                      marginLeft: "10px",
                      backgroundColor: "#DC3545",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <AutoFixHighIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Box>

                <p className="error-text">{touched.ycfid && errors.ycfid}</p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="fullName"
                  label="Full Name"
                  variant="outlined"
                  name="fullName"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName}
                  error={!!touched.fullName && !!errors.fullName}
                />
                <p className="error-text">
                  {touched.fullName && errors.fullName}
                </p>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="gender-select"
                    name="gender"
                    value={values?.gender?.toUpperCase()}
                    error={!!touched.gender && !!errors.gender}
                    label="Gender"
                    onChange={handleChange}
                  >
                    <MenuItem value={"selectGender"} disabled>
                      Select Gender
                    </MenuItem>
                    <MenuItem value={"MALE"}>Male</MenuItem>
                    <MenuItem value={"FEMALE"}>Female</MenuItem>
                    <MenuItem value={"OTHERS"}>Others</MenuItem>
                  </Select>
                  <p className="error-text">
                    {touched.gender && errors.gender}
                  </p>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Mobile Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobileNumber}
                  error={!!touched.mobileNumber && !!errors.mobileNumber}
                />
                <p className="error-text">
                  {touched.mobileNumber && errors.mobileNumber}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="alternateMobileNumber"
                  name="alternateMobileNumber"
                  label="Alternate Mobile Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.alternateMobileNumber}
                  error={
                    !!touched.alternateMobileNumber &&
                    !!errors.alternateMobileNumber
                  }
                />
                <p className="error-text">
                  {touched.alternateMobileNumber &&
                    errors.alternateMobileNumber}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="birthdate"
                  name="dob"
                  label="Birth Date"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dob}
                  error={!!touched.dob && !!errors.dob}
                  placeholder="DD/MM/YYYY"
                />
                <p className="error-text">{touched.dob && errors.dob}</p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="aadhaarNumber"
                  name="aadhaarNumber"
                  label="Aadhaar Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.aadhaarNumber}
                  error={!!touched.aadhaarNumber && !!errors.aadhaarNumber}
                />
                <p className="error-text">
                  {touched.aadhaarNumber && errors.aadhaarNumber}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="panCardNumber"
                  name="panCardNumber"
                  label="Pan card number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.panCardNumber}
                  error={!!touched.panCardNumber && !!errors.panCardNumber}
                />
                <p className="error-text">
                  {touched.panCardNumber && errors.panCardNumber}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="address"
                  label="Address"
                  name="address"
                  variant="outlined"
                  size="small"
                  multiline
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  error={!!touched.address && !!errors.address}
                />
                <p className="error-text">
                  {touched.address && errors.address}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="pincode"
                  name="pincode"
                  label="Pincode"
                  variant="outlined"
                  size="small"
                  multiline
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pincode}
                  error={!!touched.pincode && !!errors.pincode}
                />
                <p className="error-text">
                  {touched.pincode && errors.pincode}
                </p>
              </Grid>

              {/* Bank Details */}
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ m: "10px 0 -2px 0" }}>
                  Bank Details
                </Typography>
                <Typography
                  variant="p"
                  paragraph
                  fontSize={13}
                  color="grey"
                  sx={{ m: "0 0 -10px 0" }}
                >
                  Please add bank details of the beneficiary
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="bankName"
                  name="bankName"
                  label="Bank Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bankName}
                  error={!!touched.bankName && !!errors.bankName}
                />
                <p className="error-text">
                  {touched.bankName && errors.bankName}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  label="Bank Account Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bankAccountNumber}
                  error={
                    !!touched.bankAccountNumber && !!errors.bankAccountNumber
                  }
                />
                <p className="error-text">
                  {touched.bankAccountNumber && errors.bankAccountNumber}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="ifscCode"
                  name="ifscCode"
                  label="IFSC Code"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ifscCode}
                  error={!!touched.ifscCode && !!errors.ifscCode}
                />
                <p className="error-text">
                  {touched.ifscCode && errors.ifscCode}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="bankBranchName"
                  name="bankBranchName"
                  label="Bank Branch Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bankBranchName}
                  error={!!touched.bankBranchName && !!errors.bankBranchName}
                />
                <p className="error-text">
                  {touched.bankBranchName && errors.bankBranchName}
                </p>
              </Grid>

              {/* Child's Details */}
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ m: "10px 0 -2px 0" }}>
                  Child Details
                </Typography>
                <Typography
                  variant="p"
                  paragraph
                  fontSize={13}
                  color="grey"
                  sx={{ m: "0 0 -10px 0" }}
                >
                  Please add child's details
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="child_name"
                  label="Child Name"
                  name="child_name"
                  variant="outlined"
                  size="small"
                  onChange={handleChildChange}
                  value={currentChild.child_name}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="school_college_name"
                  name="school_college_name"
                  label="School/College Name"
                  variant="outlined"
                  size="small"
                  onChange={handleChildChange}
                  value={currentChild.school_college_name}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="standard_grade"
                  name="standard_grade"
                  label="Standard/Grade"
                  variant="outlined"
                  size="small"
                  onChange={handleChildChange}
                  value={currentChild.standard_grade}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" height="100%" alignItems="center">
                  <Button variant="outlined" onClick={handleAddChild}>
                    Add Child
                  </Button>
                </Box>
              </Grid>
              {/* Add table here */}
              {!!childrenList.length && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      height: "200px",
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                    }}
                  >
                    <DataGrid
                      rows={childrenList}
                      columns={columns}
                      disableSelectionOnClick
                      getRowHeight={() => "auto"}
                    />
                  </Box>
                </Grid>
              )}


<Grid item xs={12}>
                <Typography variant="h5" sx={{ m: "10px 0 -2px 0" }}>
                  Benefits
                </Typography>
                <Typography
                  variant="p"
                  paragraph
                  fontSize={13}
                  color="grey"
                  sx={{ m: "0 0 -10px 0" }}
                >
                  Please select modules to enable for user
                </Typography>
                
                <TableContainer sx={{ marginTop: 2}} component={Paper}>
                    <Table sx={{ width: "100%" }}>
                      <TableHead>
                        <TableRow sx={{border: "1px solid rgb(219, 218, 218)"}}>
                          <TableCell align="start" >Benefit</TableCell>
                          <TableCell align="start" >Amount</TableCell>
                          <TableCell align="start" >Freqeuncy</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                                <TableRow>
                                  <TableCell align="left" sx={{width: "30%"}}>Ration (राशन)</TableCell>
                                  <TableCell align="left">
                                  <TextField
                                    id="ration"
                                    name="ration"
                                    variant="standard"
                                    placeholder="Enter a amount/राशि डालें"
                                    type="number"
                                    value={benefits.ration}
                                    onChange={handleFormChange}
                                    sx={{ marginBottom: 3 }}
                                    fullWidth
                                  />
                                  </TableCell>
                                  <TableCell align="left">
                                  <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name="rationSelect"
                                      value={benefitFequency.ration}
                                      onChange={handleFormChange}
                                    >
                                      <MenuItem value={"quaterly"}>Quaterly</MenuItem>
                                      <MenuItem value={"half_yearly"}>Half Yearly</MenuItem>
                                    </Select>
                                  </FormControl>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="left">Education of Children (बच्चों की शिक्षा)</TableCell>
                                  <TableCell align="left">
                                  <TextField
                                      id="education"
                                      name="education"
                                      variant="standard"
                                      placeholder="Enter a amount/राशि डालें"
                                      onChange={handleFormChange}
                                      type="number"
                                      value={benefits.education}
                                      sx={{ marginBottom: 3 }}
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                  <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={benefitFequency.education}
                                      name="educationSelect"
                                      onChange={handleFormChange}
                                    >
                                      <MenuItem value={"half_yearly"}>Half Yearly</MenuItem>
                                      <MenuItem value={"yearly"}>Anually</MenuItem>
                                    </Select>
                                  </FormControl>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="left">Annual one time travel reimbursement to hometown (गृहनगर के लिए वार्षिक एकमुश्त यात्रा प्रतिपूर्ति)</TableCell>
                                  <TableCell align="left">
                                  <TextField
                                    id="travel"
                                    name="travel"
                                    variant="standard"
                                    type="number"
                                    value={benefits.travel}
                                    placeholder="Enter a amount/राशि डालें"
                                    onChange={handleFormChange}
                                    fullWidth
                                  />
                                  </TableCell>
                                  <TableCell align="left">
                                    <FormControl fullWidth size="small" disabled>
                                      <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={benefitFequency.travel}
                                          name="travelSelect"
                                          onChange={handleFormChange}
                                          fullWidth
                                        >
                                          <MenuItem value={"yearly"} selected={true}>Anually</MenuItem>
                                        </Select>
                                  </FormControl>
                                  </TableCell>
                                  </TableRow>

                                  <TableRow sx={{background: "#dedede"}}>
                                    <TableCell align="left" sx={{fontWeight: "bold"}}>Total Amount</TableCell>
                                    <TableCell align="left" colSpan={2}>
                                        {totalSelectedBenefitAmount}
                                    </TableCell>
                                  </TableRow>
                      </TableBody>
                    </Table>
                </TableContainer>  
              </Grid>


              <Grid item xs={12}>
                <Typography variant="h5" sx={{ m: "10px 0 -2px 0" }}>
                  Module
                </Typography>
                <Typography
                  variant="p"
                  paragraph
                  fontSize={13}
                  color="grey"
                  sx={{ m: "0 0 -10px 0" }}
                >
                  Please select modules to enable for user
                </Typography>
                <FormGroup sx={{ m: "10px 0 0 0" }}>
                  <Box display="flex">
                    <FormControlLabel
                      disabled
                      control={<Checkbox id="medical" defaultChecked />}
                      label="Medical"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={moduleCheckboxes.houseHoldVoucher}
                          id="houseHoldVoucher"
                        />
                      }
                      label="Household Vouchers"
                      onChange={handleCheckboxClick}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={moduleCheckboxes.education}
                          id="education"
                        />
                      }
                      label="Education"
                      onChange={handleCheckboxClick}
                    />
                  </Box>
                </FormGroup>
              </Grid>


              <Grid item xs={12}>
                <Typography variant="h5" sx={{ m: "10px 0 -2px 0" }}>
                  Other Details
                </Typography>
                <Typography
                  variant="p"
                  paragraph
                  fontSize={13}
                  color="grey"
                  sx={{ m: "0 0 -10px 0" }}
                >
                  Please add the yearly quota for the member
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="yearly_quota"
                  label="Yearly Quota"
                  name="yearlyQuota"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.yearlyQuota}
                  fullWidth
                  onBlur={handleBlur}
                  error={!!touched.yearlyQuota && !!errors.yearlyQuota}
                />
                <p className="error-text">
                  {touched.yearlyQuota && errors.yearlyQuota}
                </p>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Registered Member Of Film Union
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="film-union-select"
                    name="registeredFilmUnionMember"
                    value={values.registeredFilmUnionMember}
                    error={
                      !!touched.registeredFilmUnionMember &&
                      !!errors.registeredFilmUnionMember
                    }
                    label="Registered Member of Film Union"
                    onChange={handleChange}
                  >
                    <MenuItem value={"selectFilmUnion"} disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  <p className="error-text">
                    {touched.registeredFilmUnionMember &&
                      errors.registeredFilmUnionMember}
                  </p>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Active Saathi Member before 2022
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="active-saathi-member-select"
                    name="activeSaathiMemberBefore2022"
                    value={values.activeSaathiMemberBefore2022}
                    error={
                      !!touched.activeSaathiMemberBefore2022 &&
                      !!errors.activeSaathiMemberBefore2022
                    }
                    label="Active Saathi Member"
                    onChange={handleChange}
                  >
                    <MenuItem value={"selectActiveSaathiMember"} disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  <p className="error-text">
                    {touched.activeSaathiMemberBefore2022 &&
                      errors.activeSaathiMemberBefore2022}
                  </p>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Retired Person?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="retired-person-select"
                    name="retired"
                    value={values.retired}
                    error={!!touched.retired && !!errors.retired}
                    label="retired-person"
                    onChange={handleChange}
                  >
                    <MenuItem value={"selectRetired"} disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  <p className="error-text">
                    {touched.retired && errors.retired}
                  </p>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Disabled?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="disabled-select"
                    name="disabled"
                    value={values.disabled}
                    error={!!touched.disabled && !!errors.disabled}
                    label="disabled"
                    onChange={handleChange}
                  >
                    <MenuItem value={"selectGender"} disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  <p className="error-text">
                    {touched.disabled && errors.disabled}
                  </p>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="mentionDisability"
                  name="mentionDisability"
                  label="Please Mention Disability"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.mentionDisability}
                  error={
                    !!touched.mentionDisability && !!errors.mentionDisability
                  }
                  fullWidth
                  onBlur={handleBlur}
                />
                <p className="error-text">
                  {touched.mentionDisability && errors.mentionDisability}
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="salary"
                  name="salary"
                  label="Salary Range"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.salary}
                  error={!!touched.salary && !!errors.salary}
                  fullWidth
                  onBlur={handleBlur}
                />
                <p className="error-text">{touched.salary && errors.salary}</p>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {selectedMembers ? "Update Member" : "Add Member"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddMember;
