import { apiSlice } from "../../app/services/auth/apiSlice";

export const notificationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendNotification: builder.mutation({
            query: data => ({
                url: '/api/notification/create_notifications',
                method: 'POST',
                body: { ...data }
            })
        }),
    
    })
})

export const {
   useSendNotificationMutation
} = notificationApiSlice