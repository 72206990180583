import { Box, Typography, Grid, Divider, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useGetAllAnalyticsDataMutation } from "../../features/analytics/analyticsApiSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setAnalytics } from "../../features/analytics/analyticsSlice";
import { useSelector } from "react-redux";
import CircularChart from "../../components/CircularChart/CircularChart";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Spinner from "../../components/common/Spinner";


const Analytics = () => {
  const dispatch = useDispatch();
  const [getAllAnalyticsData] = useGetAllAnalyticsDataMutation();
  const { analytics } = useSelector((state) => state.analytics);

  let toDateJS = new Date();
  let fromDateJS = new Date();
  let fromMonth = fromDateJS.getMonth();
  let fromYear = fromDateJS.getFullYear();
  if (fromMonth === 0) {
    fromDateJS.setMonth(12);
    fromDateJS.setFullYear(fromYear);
  } else {
    fromDateJS.setMonth(fromMonth - 1);
  }

  const [selectedDate, setSelectedDate] = React.useState({
    fromDate: fromDateJS,
    toDate: toDateJS,
  });

  const [educationClaims, setEducationClaims] = React.useState({
    totalClaimed: 0,
    totalDisbursed: 0,
  });


  const handleButton = () => {
    let total_amount_claimed = 0;
    let total_amount_disbursed = 0;

    for (let i = 0; i < analytics?.transactions?.length; i++) {
      let requested_date = new Date(analytics?.transactions[i].requested_date);
      let selected_date = new Date(selectedDate.fromDate);
      let to_date = new Date(selectedDate.toDate);
      // Setting time to 0
      requested_date.setMilliseconds(0);
      requested_date.setSeconds(0);
      requested_date.setMinutes(0);
      requested_date.setHours(0);

      selected_date.setMilliseconds(0);
      selected_date.setSeconds(0);
      selected_date.setMinutes(0);
      selected_date.setHours(0);

      to_date.setMilliseconds(0);
      to_date.setSeconds(0);
      to_date.setMinutes(0);
      to_date.setHours(0);

      if (selected_date <= requested_date && to_date >= requested_date) {
        console.log(analytics?.transactions[i]);
        if (
          analytics?.transactions[i]?.status === "APPROVED" ||
          analytics?.transactions[i]?.status === "REJECTED"
        ) {
          console.log("He");
          total_amount_claimed += parseInt(
            analytics?.transactions[i]?.amount_requested
          );
        }

        if (analytics?.transactions[i]?.status === "APPROVED") {
          total_amount_disbursed += parseInt(
            analytics?.transactions[i]?.amount_requested
          );
        }
      }
    }

    setEducationClaims({
      totalClaimed: total_amount_claimed,
      totalDisbursed: total_amount_disbursed,
    });
  };

  useEffect(() => {
    SetAnalyticData();
  }, []);

  useEffect(() => {
    handleButton();
  }, [analytics]);

  const SetAnalyticData = async () => {
    try {
      const result = await getAllAnalyticsData().unwrap();
      console.log(result);
      dispatch(setAnalytics(result.data));
    } catch (error) {
      console.log(error);
      toast.error("Error fetching data");
    }
  };

  return (
    <Box>
      {analytics ? (
        <Grid container spacing={2}>
          <Grid item md={7} xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "5px",
                padding: "20px",
              }}
            >
              <Typography variant="h5">Overview</Typography>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                }}
              >
                <CircularChart
                  number={analytics?.dashboard_users}
                  percentage={analytics?.dashboard_users}
                  text={"Dashboard Users"}
                />
                <CircularChart
                  number={analytics?.trashed_members}
                  percentage={
                    (analytics?.trashed_members / analytics?.total_members) *
                    100
                  }
                  text={"Deleted Members"}
                />
                <CircularChart
                  number={analytics?.news_count}
                  percentage={(analytics?.news_count / 100) * 100}
                  text={"News Published"}
                />
                <CircularChart
                  number={analytics?.pending_claims}
                  percentage={(analytics?.pending_claims / 100) * 100}
                  text={"Pending Claims"}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "5px",
                padding: "20px",
              }}
            >
              <Typography variant="h5">Active Mobile Users</Typography>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                }}
              >
                <CircularChart
                  number={analytics?.total_members}
                  percentage={100}
                  text={"Total Member"}
                />
                <CircularChart
                  number={analytics?.total_education_members}
                  percentage={
                    (analytics?.total_education_members /
                      analytics?.total_members) *
                    100
                  }
                  text={"Education Members"}
                />
                <CircularChart
                  number={analytics?.total_household_members}
                  percentage={
                    (analytics?.total_household_members /
                      analytics?.total_members) *
                    100
                  }
                  text={"Household Member"}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "5px",
                padding: "20px",
              }}
            >
              <Typography variant="h5">Education Member Claims</Typography>
              <Divider />
              <Box paddingTop={"20px"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DesktopDatePicker
                      label="From Date"
                      inputFormat="DD/MM/YYYY"
                      // value={selectedDate.fromDate}
                      onChange={(e) => {
                        setSelectedDate((prevValue) => {
                          return {
                            ...prevValue,
                            fromDate: e,
                          };
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <DesktopDatePicker
                      label="To Date"
                      inputFormat="DD/MM/YYYY"
                      // value={selectedDate.toDate}
                      onChange={(e) => {
                        setSelectedDate((prevValue) => {
                          return {
                            ...prevValue,
                            toDate: e,
                          };
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
              <Box paddingTop={"8px"}>
                <Button variant="outlined" onClick={handleButton}>
                  Check
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <CircularChart
                  number={educationClaims.totalClaimed}
                  percentage={0}
                  text={"Total Claimed"}
                />
                <Typography variant="h5">-V/S-</Typography>
                <CircularChart
                  number={educationClaims.totalDisbursed}
                  percentage={0}
                  text={"Total Disbursed"}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box justifyContent="center" sx={{
          justifyContent: "center",
          height: "80vh",
          alignItems: "center",
          transform: "scale(2)"
        }}>
           <Spinner />
        </Box>
       
      )}
    </Box>
  );
};

export default Analytics;
