import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { width } from "@mui/system";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  height: "100%",
  bgcolor: "background.paper",
  border: "0",
  borderRadius: "5px",
  boxShadow: 24,
  overflow: "scroll",
  pt: 2,
  px: 4,
  pb: 3,
  m: "20px 0",
};

const ShowPosts = ({isShowPostModalOpen, closeShowPostModel }) => {

  const post = useSelector((state) => state.archive.selectedPost);

  return (
    <div>
      <Modal
        open={isShowPostModalOpen}
        onClose={closeShowPostModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" onClick={closeShowPostModel}>Close</Button>
          </Box>

          <Box
            sx={{
              maxWidth: "500px",
              margin: "0 auto",
            }}
          >
            <img
              src={post?.image_url}
              alt="featured image"
              width="100%"
            />
            <Typography
              textAlign="center"
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {post?.title}
            </Typography>
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}> */}
            <div
              style={{ maxWidth: "500px" }}
              dangerouslySetInnerHTML={{
                __html: post?.body,
              }}
            ></div>
            {/* </Typography> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ShowPosts;
