import { Box, Button } from "@mui/material";
import React from "react";
import ClinicTable from "../../components/Medical/ClinicTable";

const Medical = () => {
  const [medicalMenu, setMedicalMenu] = React.useState({
    clinic: true,
  });
  return (
    <Box>
      <Box>
        <Button variant="outlined">Clinics</Button>
      </Box>
      <Box marginTop={1}>
        {medicalMenu.clinic  && <ClinicTable /> }
      </Box>
    </Box>
  );
};

export default Medical;
