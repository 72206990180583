import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

import Modal from "@mui/material/Modal";
import useMediaQuery from '@mui/material/useMediaQuery';

import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useCreateTutorialMutation,
  useDeleteTutorialMutation,
  useGetTutorialByLanguageMutation,
  useUpdateTutorialMutation,
} from "../../features/tutorials/tutorialApiSlice";

const Tutorial = () => {
  const [isCreateTutorial, setIsCreateTutorial] = useState(false);

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [data, setData] = useState({ title: "", video_url: "", language: "" });
  const [tutorialData, setTutorialData] = useState(null);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [createTutorial] = useCreateTutorialMutation();
  const [getTutorialByLanguage] = useGetTutorialByLanguageMutation();
  const [updateTutorial] = useUpdateTutorialMutation();
  const [deleteTutorial] = useDeleteTutorialMutation();
  const [beneficiaryMenu, setBeneficiary] = useState({
    members: true,
    addMember: false,
    deletedMember: false,
    showMemberDetails: false,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    
    bgcolor: "background.paper",
    border: "0",
    borderRadius: "5px",
    boxShadow: 24,
    overflow: "scroll",
    pt: 2,
    px: 4,
    pb: 3,
    m: "20px 0",
  };
  const matches = useMediaQuery('(max-width:750px)');

  const columns = [
    {
      field: "title",
      headerName: "Title",
      minWidth: 200,
    },
    {
      field: "video_url",
      headerName: "Video_url",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "language",
      headerName: "Language",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 180,
      filterable: false,
      sortable: false,
      renderCell: (param) => (
        <Box width="100%">
          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              setSelectedTutorial(param.row);
              setViewModel(true);
            }}
          >
            <VisibilityIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>

          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              console.log("Param", param.row);
              setSelectedTutorial(param.row);
              setEditModel(true);
            }}
          >
            <EditIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
          <IconButton
            sx={{ width: "40px" }}
            onClick={ ()=>deleteOnClick(param)}
          >
            <DeleteIcon
              sx={{
                width: "20px",
                color: "#ba000d",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  // function to select the language
  async function showDataBasedOnLanguages(language) {
    
    try{
        const result = await getTutorialByLanguage({
      language: language,
    }).unwrap();
    toast.success("Successfully Language selected !");
    setTutorialData(result.data);
    setEditModel(false);
    }catch{
            toast.error(" Something went wrong  !");
    } 
  }

  //    function for adding new Tutorials
  const addOnClick = async function () {
    try {
      const result = await createTutorial({
        language: data.language,
        title: data.title,
        video_url: data.video_url,
      }).unwrap();

      toast.success("Successfully Tutorial added !");
      setIsCreateTutorial(false);
      setData({ title: "", video_url: "", language: "" });
      if(tutorialData === null){
        setTutorialData([result.data[0]]);
        return;
      }
      
      setTutorialData((prevValue) => {
        return [
          ...prevValue,
          result.data[0]
        ]
      })
    } catch (error) {
      toast.error("Something went wrong !");
    }
  };
  // function for adding new Tutorials
  const updateOnClick = async function () {
    try {
      const updatedTutorial = {
        tutorial_id: selectedTutorial.id,
        video_url: selectedTutorial.video_url,
        title: selectedTutorial.title,
        language: selectedTutorial.language,
      };

      // Make the API call to update the tutorial
      const result = await updateTutorial(updatedTutorial).unwrap();

      // Update the state with the new data
      const updatedData = tutorialData.map((tutorial) =>
        tutorial.id === result.data[0].id
          ? {
              ...tutorial,
              title: result.data[0].title,
              video_url: result.data[0].video_url,
            }
          : tutorial
      );

      setTutorialData(updatedData);

      toast.success("Successfully Edited !");
      setEditModel(false);
     
    } catch (e) {
      toast.error("Something went wrong !");
    }
  };
  
  const deleteOnClick = async function(param){
        try {
                const data = tutorialData;
                const val = await deleteTutorial({
                  tutorial_ids: [param.row.id],
                });

                console.log("reslut:", tutorialData);
                const res = tutorialData.filter((x) => {
                  return x.id !== param.row.id;
                });
                toast.success("Successfully Deleted !");
                setTutorialData(res);
              } catch {
                toast.error("something went wrong!");
              }
  }
  return (
    <>
      {!beneficiaryMenu.showMemberDetails && (
        <Box className="beneficiary-button-container">
          <Box sx={{ display: "flex" }}>
            <Button
           
              variant={"outlined"}
              
              id="addMember"
               className="beneficiary-submenu-button"
              onClick={() => setIsCreateTutorial(true)}
            >
              Add Tutorial
            </Button>
            <Box  paddingLeft={2}>
              <FormControl sx={{ width: "200px" }}>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="language-select"
                  value={data.language}
                  label="Language"
                  onChange={(e) =>
                    setData({ ...data, language: e.target.value })
                  }
                >
                  <MenuItem value={"selectLanguage"} disabled>
                    Select Language
                  </MenuItem>
                    <MenuItem onClick={() => showDataBasedOnLanguages("English")} value={"english"}>
                     
                        English
                      
                    </MenuItem>

                     <MenuItem onClick={() => showDataBasedOnLanguages("Hindi")} value={"hindi"}>
                     
                        Hindi
                      
                    </MenuItem>
                     <MenuItem onClick={() => showDataBasedOnLanguages("Marathi")} value={"marathi"}>
                     
                        Marathi
                      
                    </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Modal
            open={isCreateTutorial}
            onClose={() => setIsCreateTutorial(true)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{
              width: matches ? "95vw" : "50vw"
            }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <Typography variant="h5">Add Tutorial</Typography>
                <Button
                  variant="contained"
                  onClick={() => setIsCreateTutorial(false)}
                >
                  Close
                </Button>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    value={data.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                    label="Title"
                    variant="outlined"
                    name="title"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="video_url"
                    value={data.video_url}
                    onChange={(e) =>
                      setData({ ...data, video_url: e.target.value })
                    }
                    label="Video Url"
                    variant="outlined"
                    name="video_url"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>

              <FormControl sx={{ width: "200px", marginTop: "20px" }}>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="language-select"
                  value={data.language}
                  label="Language"
                  onChange={(e) =>
                    setData({ ...data, language: e.target.value })
                  }
                >
                  <MenuItem value={"selectLanguage"} disabled>
                    Select Language
                  </MenuItem>
                  <MenuItem value={"english"}>English</MenuItem>
                  <MenuItem value={"hindi"}>Hindi</MenuItem>
                  <MenuItem value={"marathi"}>Marathi</MenuItem>
                </Select>
              </FormControl>
              <Grid pt={2} item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => addOnClick()}

                  
                >
                  Save
                </Button>
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={viewModel}
            onClose={() => setViewModel(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{
              width: matches ? "95vw" : "50vw"
            }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <Typography variant="h5">View Tutorial</Typography>
                <Button variant="contained" onClick={() => setViewModel(false)}>
                  Close
                </Button>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Title"
                    value={selectedTutorial?.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                    label="Title"
                    variant="outlined"
                    name="Title"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Video_url"
                    value={selectedTutorial?.video_url}
                    onChange={(e) =>
                      setData({ ...data, video_url: e.target.value })
                    }
                    label="Video_url"
                    variant="outlined"
                    name="Video_url"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Language"
                    value={selectedTutorial?.language}
                    label="language"
                    variant="outlined"
                    name="language"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={editModel}
            onClose={() => setEditModel(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{
              width: matches ? "95vw" : "50vw"
            }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <Typography variant="h5">Edit Tutorial</Typography>
                <Button variant="contained" onClick={() => setEditModel(false)}>
                  Close
                </Button>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Title"
                    value={selectedTutorial?.title}
                    onChange={(e) =>
                      setSelectedTutorial((prevValue) => {
                        return {
                          ...prevValue,
                          title: e.target.value,
                        };
                      })
                    }
                    label="Title"
                    variant="outlined"
                    name="Title"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Video_url"
                    value={selectedTutorial?.video_url}
                    onChange={(e) =>
                      setSelectedTutorial((prevValue) => {
                        return {
                          ...prevValue,
                          video_url: e.target.value,
                        };
                      })
                    }
                    label="Video_url"
                    variant="outlined"
                    name="Video_url"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Language"
                    value={selectedTutorial?.language}
                    label="language"
                    variant="outlined"
                    name="language"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
              <Grid item xs={12} paddingTop={3}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    updateOnClick();
                  }}
                >
                  Update
                </Button>
              </Grid>
            </Box>
          </Modal>
        </Box>
      )}
      { 
        <Box
          sx={{
            height: "70vh",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
            {
              tutorialData ? (
                          <DataGrid
            rows={tutorialData}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            density="compact"
          />
              ) : <Typography      paddingTop={25}  textAlign={"center"}  flexGrow={1}> Loading... </Typography>
            }
        </Box>
      }
    </>
  );
};

export default Tutorial;
