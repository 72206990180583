import React, { useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useUpdateDashboardUserDetailsMutation } from "../../features/dashboardUserManagement/dashboardUserManagementApiSlice";
import { setUserInfo } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const EMAILREGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const DashboardUserDetailUpdate = () => {
  const loggedInUser = useSelector((state) => state.auth.userInfo);

  const [uppdateDashboardUserDetails] = useUpdateDashboardUserDetailsMutation();

  const dispatch = useDispatch();

  const selectedDashboardUser = useSelector(
    (state) => state.dashboardUser.selectedDashboardUser
  );

  console.info("Loggined user" + JSON.stringify(loggedInUser?.id));
  console.info("Selected User" + JSON.stringify(selectedDashboardUser?.id));

  const [disabled, setDisabled] = React.useState(true);
  const [userDetailForm, setUserDetailForm] = React.useState({
    firstName: selectedDashboardUser ? selectedDashboardUser.first_name : "",
    lastName: selectedDashboardUser ? selectedDashboardUser.last_name : "",
    email: selectedDashboardUser
      ? selectedDashboardUser.hasOwnProperty("email")
        ? selectedDashboardUser.email
        : selectedDashboardUser.email_id
      : "",
    role: selectedDashboardUser ? selectedDashboardUser.role : "",
  });

  // use to store the errors for the field
  const [detailsError, setDetailsError] = React.useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
  });

  const handleChange = (e) => {
    setUserDetailForm((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });

    setDetailsError((prevValue) => {
      return {
        ...prevValue,
        [e.target.name + "Error"]: "",
      };
    });
  };

  //   Use this function to set error
  const setError = (field, error) => {
    setDetailsError((prevValue) => {
      return {
        ...prevValue,
        [field]: error,
      };
    });
  };

  const validate = () => {
    let formError = false;
    if (userDetailForm.firstName.length < 3) {
      formError = true;
      setError(
        "firstNameError",
        "Length should be greater or equal to 3 characters"
      );
    }

    if (userDetailForm.lastName.length < 3) {
      formError = true;
      setError(
        "lastNameError",
        "Length should be greater or equal to 3 characters"
      );
    }

    if (!userDetailForm.email.match(EMAILREGEX)) {
      formError = true;
      setError("emailError", "Please enter a valid email");
    }

    if (formError) {
      return "error";
    } else {
      return "success";
    }
  };

  const handleSubmit = async () => {
    if (validate() === "error") {
      return;
    }

    try {
      // While sending in the payload add the id

      userDetailForm.id = selectedDashboardUser?.id;
      let result = await uppdateDashboardUserDetails(userDetailForm).unwrap();
      console.log(result);
      let newSelectedUser = JSON.parse(JSON.stringify(selectedDashboardUser));
      newSelectedUser.first_name = userDetailForm.firstName;
      newSelectedUser.last_name = userDetailForm.lastName;
      newSelectedUser.email = userDetailForm.email;
      newSelectedUser.role = userDetailForm.role;
      dispatch(setUserInfo(newSelectedUser));
      toast.success("Success");
      setDisabled(true);
    } catch (err) {
      console.log(err);
      if (err?.data?.message) {
        toast.error(err?.data?.message);
      } else {
        toast.error("There was some error");
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "5px",
        p: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={40}
          >
            <Typography variant="h5">Personal Details</Typography>
            <IconButton
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <EditIcon sx={{ color: disabled ? "" : "red !important" }} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="firstName"
            label="First Name"
            variant="outlined"
            name="firstName"
            fullWidth
            onChange={handleChange}
            value={userDetailForm.firstName}
            disabled={disabled ? true : false}
          />
          <p className="error-text" style={{ marginBottom: "-5px" }}>
            {detailsError?.firstNameError ? detailsError.firstNameError : ""}
          </p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="lastName"
            variant="outlined"
            label="Last Name"
            name="lastName"
            fullWidth
            onChange={handleChange}
            value={userDetailForm.lastName}
            disabled={disabled ? true : false}
          />
          <p className="error-text" style={{ marginBottom: "-5px" }}>
            {detailsError?.lastNameError ? detailsError.lastNameError : ""}
          </p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="emailID"
            variant="outlined"
            label="Email ID"
            name="email"
            fullWidth
            value={userDetailForm.email}
            onChange={handleChange}
            disabled={disabled ? true : false}
          />
          <p className="error-text" style={{ marginBottom: "-5px" }}>
            {detailsError?.emailError ? detailsError.emailError : ""}
          </p>
        </Grid>
        {loggedInUser.id !== selectedDashboardUser.id && (
          <Grid item xs={12}>
            <FormControl fullWidth disabled={disabled ? true : false}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="role-select"
                name="role"
                value={userDetailForm.role}
                label="Role"
                onChange={handleChange}
              >
                <MenuItem value={"ADMIN"}>Admin</MenuItem>
                <MenuItem value={"SUPERADMIN"}>Super Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {!disabled && (
          <Grid item xs={12}>
            <Button variant="outlined" onClick={handleSubmit}>
              Update
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DashboardUserDetailUpdate;
