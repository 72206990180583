import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

const CircularChart = ({ number, percentage, text }) => {
    
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box width={isMobile ? 80 : 100}>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <Box sx={{ position: "absolute", top: 0, left: 0 }}>
          <CircularProgress
            variant="determinate"
            value={100}
            size={isMobile ? 80 : 100}
            sx={{
              color: "#E8E8E8",
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {number}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            variant="determinate"
            value={percentage}
            size={isMobile ? 80 : 100}
          />
        </Box>
      </Box>
      <Typography textAlign={"center"} fontSize={isMobile ? 14 : 17}>{text}</Typography>
    </Box>
  );
};

export default CircularChart;
