import React, { useEffect } from "react";
import { Box, Typography, Button, Icon, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import {
  deletePosts,
  setPosts,
  setSelectedPost,
} from "../../features/archive/archiveSlice";
import {
  useGetPostsMutation,
  useDeletePostsMutation,
} from "../../features/archive/archiveApiSlice";
import ShowPosts from "../ShowPosts";
import { toast } from "react-toastify";
import AddPost from "../EditPost";
import {
  useDeleteMembersMutation,
  useGetAllDeletedMembersMutation,
  useGetAllMembersMutation,
  usePermanentDeleteMembersMutation,
  useRestoreMembersMutation,
} from "../../features/beneficiary/beneficiaryApiSlice";
import {
  setDeletedMembers,
  setMembers,
} from "../../features/beneficiary/beneficiarySlice";

const DeletedMembersTable = () => {
  const [selectionModel, setSelectionModel] = React.useState([]); // holds all the selected post
  const [openShowPostModel, setOpenShowPostModel] = React.useState(false);
  const [openEditPostModel, setOpenEditPostModel] = React.useState(false);

  const dispatch = useDispatch();

  const [getAllDeletedMembers] = useGetAllDeletedMembersMutation();
  const [restoreMembers] = useRestoreMembersMutation();
  const [permanentDeleteMembers] = usePermanentDeleteMembersMutation();

  useEffect(() => {
    fetchAllDeletedMembers();
  }, []);

  // get all the members from the server who are not trashed
  const fetchAllDeletedMembers = async () => {
    const deletedMembers = await getAllDeletedMembers().unwrap();
    if (deletedMembers.status === 500) {
      toast.error("Internal Server Erorr");
      return;
    }
    dispatch(setDeletedMembers(deletedMembers.data));
  };

  const deletedMembersList = useSelector(
    (state) => state.beneficiary.deletedMembers
  );

  const restoreBeneficiary = async (memberIds) => {
    console.log(memberIds);
    try {
      let membersToDelete = {
        members_id: memberIds,
      };
      await restoreMembers(membersToDelete).unwrap();
      toast.success(
        "Successfully restored members"
      );
      // clearing the state
      setSelectionModel([]);
      return "success";
    } catch (err) {
      console.log(err);
      if (err.status === 404) {
        toast.error("Please select atleast one member");
        return "error";
      }
      toast.error("Internal Server Error");
      return
    }
  };

  const handleRestoreBeneficiary = async (id) => {
    try {
      const selectedIDs = new Set([id]);
      let filteredMembers = deletedMembersList.filter(
        (x) => !selectedIDs.has(x.id)
      );
      const result = await restoreBeneficiary([id]);
      if (result !== "error") {
        dispatch(setDeletedMembers(filteredMembers));
        return;
      }
      return;
    } catch (error) {
      console.log(error);
      toast.err(error?.data?.message || "Something went wrong")
    }
  }

  const handlePermantDelete = async(id) => {
    try {

        await permanentDeleteMembers({id: id}).unwrap();
       
        let filteredMembers = deletedMembersList.filter(member => member.id !== id);

        dispatch(setDeletedMembers(filteredMembers));
        toast.success("Permantly Deleted Member");
        
    } catch (error) {
        console.log(error);
        toast.error(error?.data?.message || "Something went wrong")
    }
  }
  

  const columns = [
    { field: "ycf_id", headerName: "YCF ID", flex: 1, minWidth: 110 },
    {
      field: "profile_photo",
      headerName: "Photo",
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (param, index) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <img src={param.value} alt="user profile" style={{ height: "100%" }} />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      minWidth: 200,
      // sortable: false,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      flex: 2,
      minWidth: 140,
    },
    {
      field: "aadhaar_number",
      headerName: "Aadhaar Number",
      flex: 2,
      minWidth: 140,
    },
    { field: "modules", headerName: "Benefits", flex: 2, minWidth: 165 },
    {
      field: "options",
      headerName: "Options",
      flex: 2,
      minWidth: 140,
      filterable: false,
      renderCell: (param) => (
        <Box width="100%">
          <IconButton
            
            variant="outlined"
            sx={{ width: "40px" }}
            onClick={async () => handleRestoreBeneficiary(param.row.id)}
          >
            <Typography variant="h6" fontSize={9}>
              <SettingsBackupRestoreIcon/>
            </Typography>
          </IconButton>
          <IconButton
            
            variant="outlined"
            sx={{ width: "40px" }}
            onClick={async () => handlePermantDelete(param.row.id)}
          >
            <Typography variant="h6" fontSize={9}>
              <DeleteIcon color="primary" />
            </Typography>
          </IconButton>
        </Box>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {/* When user selects some data then only display it */}
        {selectionModel.length > 0 && (
          <RestoreAllButton
            selectionModel={selectionModel}
            deletedMembersList={deletedMembersList}
            restoreBeneficiary={restoreBeneficiary}
            dispatch={dispatch}
          />
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "80vh",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        <DataGrid
          rows={deletedMembersList}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          density="compact"
          components={{ Toolbar: CustomToolbar }}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
        />
      </Box>
    </>
  );
};

export default DeletedMembersTable;

// Custom restore all button
const RestoreAllButton = ({
  selectionModel,
  deletedMembersList,
  restoreBeneficiary,
  dispatch,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: "10px",
      }}
    >
      <Button
        variant="outlined"
        onClick={async () => {
          if (selectionModel.length === 0) {
            toast.error("Please select atleast 1 member to restore");
            return;
          }
          const selectedIDs = new Set(selectionModel);
          let filteredMembers = deletedMembersList.filter(
            (x) => !selectedIDs.has(x.id)
          );
          const result = await restoreBeneficiary(selectionModel);
          if (result != "error") {
            dispatch(setDeletedMembers(filteredMembers));
            return;
          }
          return;
        }}
      >
        <Typography variant="h6" fontSize={9}>
          Restore
        </Typography>
      </Button>
    </Box>
  );
};
