import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { uploadNotificationImage } from "../../api/awsS3.api";
import { toast } from "react-toastify";
import { useSendNotificationMutation } from "../../features/notification/notificationApiSlice";
import axios from "axios";

const SendNotification = ({ sendToAll, memberIds, setOpenModal }) => {
  const [disableForm, setDisableForm] = React.useState({
    disableAll: false,
    disableImageUpload: false,
  });
  const [showImageUploadOptions, setShowImageUploadOptions] =
    React.useState(false);
  const [formField, setformFields] = React.useState({
    title: "",
    body: "",
    imageUrl: "",
  });

  const [formFieldError, setFormFieldError] = React.useState({
    titleError: "",
  });

  const [sendNotification] = useSendNotificationMutation();

  //Upload notification image
  const handleNotificationImageUpload = async (e) => {
    setDisableForm((prevValue) => {
      return { ...prevValue, disableAll: true };
    });
    try {
     
      let notification_image = await uploadNotificationImage(e.target.files[0])
      setformFields((prevValue) => {
        return {
          ...prevValue,
          imageUrl: notification_image?.data?.image_location[0],
        };
      });
      setDisableForm((prevValue) => {
        return { ...prevValue, disableAll: false };
      });
    } catch (error) {
      console.log(error);
      toast.error(
        "There was some error uploading image, please add a image url"
      );
      setDisableForm((prevValue) => {
        return { ...prevValue, disableAll: false };
      });
    }
  };

  const handleChange = (e) => {
    console.log("Send to all " + sendToAll);

    if (e.target.name === "imageUrl") {
      if (formField.imageUrl !== "") {
        setDisableForm((prevValue) => {
          return { ...prevValue, disableImageUpload: true };
        });
      }
    }

    setformFields((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleCheckBox = (e) => {
    setShowImageUploadOptions(e.target.checked);
  };

  const validate = () => {
    if (formField.title === "") {
      setFormFieldError((prevValue) => {
        return {
          ...prevValue,
          titleError: "Please enter a title",
        };
      });
    }
  };

  const handleSubmit = async () => {
    if (validate() === "error") return;

    // memberIds are in this format = [1, 2]
    // comvert it to ["id": 1, "id": 2]

    let newMemberIds = [];

    console.log("meber id length" + memberIds.length);

    for (let i = 0; i < memberIds.length; i++) {
      const memberId = memberIds[i];
      newMemberIds.push({ id: memberId });
    }

    // Built the object to sent
    let notificationPayload = {
      title: formField.title,
      body: formField.body,
      for_all_members: sendToAll,
      image_url: formField.imageUrl,
      member_ids: newMemberIds,
    };

    console.log(notificationPayload);

    try {
      let result = await sendNotification(notificationPayload).unwrap();
      console.log("result " + result);
      setformFields({
        title: "",
        body: "",
        imageUrl: "",
      });
      setOpenModal(false);
      toast.success("Success");
    } catch (error) {
      console.log(error);
      toast.error(error.data?.message);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Title */}
        {console.log(memberIds)}
        <Grid item xs={12}>
          <TextField
            id="title"
            label="Title"
            variant="outlined"
            name="title"
            size="small"
            fullWidth
            onChange={handleChange}
            disabled={disableForm.disableAll}
          />
          <p className="error-text">{formFieldError.titleError}</p>
        </Grid>
        {/* Body */}
        <Grid item xs={12}>
          <TextField
            id="body"
            label="body"
            variant="outlined"
            name="body"
            size="small"
            multiline
            fullWidth
            disabled={disableForm.disableAll}
            onChange={handleChange}
          />
          <p className="error-text">
            {/* {touched.fullName && errors.fullName} */}
          </p>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            sx={{ marginLeft: "0px" }}
            control={<Checkbox onChange={handleCheckBox} />}
            label="Add image?"
            labelPlacement="start"
          />
        </Grid>
        {/* Image */}
        {showImageUploadOptions && (
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="imageUrl"
              label="ImageUrl"
              variant="outlined"
              name="imageUrl"
              size="small"
              value={formField.imageUrl}
              disabled={disableForm.disableAll}
              onChange={handleChange}
              fullWidth
            />
            <Typography marginLeft="12px" width="45px">
              -OR-
            </Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ height: "40px", width: "180px", marginLeft: "10px" }}
                component="label"
                disabled={disableForm.disableAll}
              >
                Upload Image
                <input
                  hidden
                  accept="image/*"
                  onChange={handleNotificationImageUpload}
                  type="file"
                />
              </Button>
            </Box>
          </Grid>
        )}
        {/* Send button */}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={handleSubmit}
            disabled={disableForm.disableAll}
          >
            Send Notification
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendNotification;
