import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { width } from "@mui/system";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Publisher from "../pages/publisher/Publisher";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  height: "100%",
  bgcolor: "background.paper",
  border: "0",
  borderRadius: "5px",
  boxShadow: 24,
  overflow: "scroll",
  pt: 2,
  px: 4,
  pb: 3,
  m: "20px 0",
};

const EditPost = ({ isEditPostModalOpen, closeEditPostModel }) => {
  return (
    <div>
      <Modal
        open={isEditPostModalOpen}
        onClose={closeEditPostModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" onClick={closeEditPostModel}>
              Close
            </Button>
          </Box>
          <Publisher closeEditPostModel={closeEditPostModel}/>
        </Box>
      </Modal>
    </div>
  );
};

export default EditPost;
