import { apiSlice } from "../../app/services/auth/apiSlice";

export const applicationFormApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createApplicationForm: builder.mutation({
            query: data => ({
                url: '/api/admin/create_application',
                method: 'POST',
                body: { ...data }
            })
        }),
        updateApplicationForm: builder.mutation({
            query: data => ({
                url: '/api/applicationform/status',
                method: 'POST',
                body: { ...data }
            })
        }),
        getAllPendingApplicationForm: builder.mutation({
            query: () => ({
                url: "/api/applicationforms/pending",
                method: 'GET'
            })
        }),
        getAllAcceptedApplicationForm: builder.mutation({
            query: () => ({
                url: "/api/applicationforms/accepted",
                method: 'GET'
            })
        }),
        getAllRejectedApplicationForm: builder.mutation({
            query: () => ({
                url: "/api/applicationforms/rejected",
                method: 'GET'
            })
        }),
        deleteApplicationForm: builder.mutation({
            query: data => ({
                url: `/api/applicationform/${data.id}`,
                method: 'DELETE'
            })
        }),

    })
})

export const {
    useCreateApplicationFormMutation,
    useUpdateApplicationFormMutation,
    useGetAllAcceptedApplicationFormMutation,
    useGetAllPendingApplicationFormMutation,
    useGetAllRejectedApplicationFormMutation,
    useDeleteApplicationFormMutation
} = applicationFormApiSlice