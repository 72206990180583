import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Paper,
  Divider,
  Modal,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import "./memberdetails.css";
import { setSelectedMember } from "../../features/beneficiary/beneficiarySlice";
import { styled } from "@mui/material/styles";
import styles from "./memberdetails.module.css";
import AddIcon from "@mui/icons-material/Add";
import ChangeMemberPassword from "../../components/common/ChangeMemberPassword";
import { toast } from "react-toastify";
import {
  useAddInsuranceLinkMutation,
  useUpdateMemberPasswordMutation,
} from "../../features/beneficiary/beneficiaryApiSlice";
import { uploadInsurance } from "../../api/awsS3.api";
import { useGetDataMutation } from "../../features/beneficiary/memberBenefitsApiSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const ChildrenGrid = styled(DataGrid)`
  & .css-f3jnds-MuiDataGrid-columnHeaders {
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
  }

  & .css-1w5m2wr-MuiDataGrid-virtualScroller {
    margin-top: 30px !important;
  }

  & .css-17jjc08-MuiDataGrid-footerContainer {
    min-height: 30px !important;
  }
`;

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MemberDetails = ({ BeneficiaryMenuNavigator }) => {
  const { members, selectedMembers } = useSelector(
    (state) => state.beneficiary
  );
  const [beneficiaryMenu, setBeneficiary] = useState({
    education: true,
    travel: false,
    ration: false,
    
  });
  

  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [data, setData] = useState([]);
  const [insuranceImage, setInsuranceImage] = useState(
    selectedMembers?.insurance_card
  );
  const [insuranceImageLocal, setInsuranceImageLocal] = useState(null);

  const dispatch = useDispatch();
  const [fetchData] = useGetDataMutation();
  const [updateMemberPassword] = useUpdateMemberPasswordMutation();
  const [addInsuranceLink] = useAddInsuranceLinkMutation();

  const columns = [
    { field: "child_name", headerName: "Name", flex: 1 },
    { field: "school_college_name", headerName: "School", flex: 1 },
    { field: "standard_grade", headerName: "Standard", flex: 1 },
  ];

  const transactionsColumn = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "amount_requested", headerName: "Amount Requested", flex: 1 },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      flex: 1,
      renderCell: (param) => {
        let UTCDate = new Date(param.row.requested_date);
        console.log("The date is" + months[UTCDate.getMonth()]);
        let date =
          months[UTCDate.getMonth()] +
          " " +
          UTCDate.getDate() +
          ", " +
          UTCDate.getFullYear();
        return date;
      },
    },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "admin_name", headerName: "Approved By", flex: 1 },
    { field: "module", headerName: "Module", flex: 1 },
  ];

  
  const handleInsuranceUpload = async () => {
    try {
      const result = await uploadInsurance(insuranceImageLocal);
      const body = {
        member_id: selectedMembers.id,
        link: result?.data?.image_location[0],
      };
      const image_link = await addInsuranceLink(body);
      console.log(
        "Anuj " + JSON.stringify(image_link?.data?.data?.insurance_card)
      );
      setInsuranceImage(image_link?.data?.data?.insurance_card);
      toast.success("Uploaded succesfully");
    } catch (error) {
      toast.error("Error Uploading File");
    }
  };

  const handleImageSelect = (e) => {
    console.log(e.target.files[0]);
    setInsuranceImageLocal(e.target.files[0]);
  };

  const handleInsuranceDownload = () => {
    console.log(insuranceImage);
    const insuranceDownloadLink = document.createElement("a");
    insuranceDownloadLink.style.display = "none";
    insuranceDownloadLink.href = insuranceImage;
    insuranceDownloadLink.download = "insurance.pdf";
    document.body.appendChild(insuranceDownloadLink);
    insuranceDownloadLink.click();
  };

  
  

  async function call() {
    try {
      console.log("members", selectedMembers);
      const result = await fetchData({
        // ...

        member_id: selectedMembers.id,
      }).unwrap();
      setData(result.member_benefits);

      
    } catch (error) {
     toast.erro("Something went wrong !")
    }
  }
  
  useEffect(() => {
    call();
   
  }, []);

  

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {/* When user selects some data then only display it */}
        {/* <AddTransactionButton setOpenTransactionModal={setOpenTransactionModal}/> */}
      </GridToolbarContainer>
    );
  }

  return (
    <Box>
      <Modal
        open={openTransactionModal}
        onClose={() => setOpenTransactionModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="member-details-transaction-model">
          {/* <AddMemberTransaction setOpenTransactionModal={setOpenTransactionModal}/>  */}
          <ChangeMemberPassword />
        </Box>
      </Modal>
      <Modal
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="member-details-transaction-model">
          <ChangeMemberPassword setOpenChangePassword={setOpenChangePassword} />
        </Box>
      </Modal>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              BeneficiaryMenuNavigator("members");
              dispatch(setSelectedMember(null));
            }}
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                // Change password
                handleInsuranceUpload();
              }}
              sx={{
                marginRight: "20px",
              }}
            >
              Upload Insurance Card
            </Button>
            {/* <Button
              variant="outlined"
              onClick={() => {
                // Change password
                handlePasswordReset();
                // setOpenChangePassword(true);
              }}
            >
              Reset Password
            </Button> */}
          </Box>
        </Grid>
        {/* Personal Details */}
        <Grid item md={6} xs={12} className="member-details-grid">
          <Box>
            <Paper
              sx={{ minHeight: "280px", p: "20px" }}
              className="member-details-paper"
            >
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="h5"
                  fontSize={18}
                  fontWeight={900}
                  marginBottom="4px"
                >
                  Personal Details
                </Typography>
                <Box>
                  {selectedMembers?.modules?.map((moduleName, index) => {
                    return (
                      <Typography
                        variant="p"
                        key={index}
                        fontSize={12}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "3px",
                          padding: "4px",
                          borderColor: "#DC3545",
                          marginLeft: "3px",
                        }}
                      >
                        {moduleName}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
              <Divider sx={{ backgroundColor: "#DC3545" }} />
              {/* Image div */}
              <Box
                display="flex"
                marginTop="20px"
                className="member-details-personal-details-section"
              >
                {/* Member Profile Picture container */}
                <Box>
                  <Box
                    sx={{
                      backgroundColor: "grey",
                      backgroundImage: `url(${selectedMembers?.profile_photo})`,
                      height: "130px",
                      width: "120px",
                      backgroundSize: "cover",
                      backgroundPositionX: "50%",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></Box>
                </Box>
                {/* Text content */}
                <Box
                  marginLeft="30px"
                  className="member-details-personal-details"
                >
                  {/* Member Name */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" fontSize={14}>
                      Name:{" "}
                    </Typography>
                    <Typography variant="p" fontSize={13} marginLeft="5px">
                      {selectedMembers?.full_name}
                    </Typography>
                  </Box>
                  {/* YCF ID */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" fontSize={14}>
                      YCF ID:{" "}
                    </Typography>
                    <Typography variant="p" fontSize={13} marginLeft="5px">
                      {selectedMembers?.ycf_id}
                    </Typography>
                  </Box>
                  {/* Phone Number */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" fontSize={14}>
                      Mobile Number:{" "}
                    </Typography>
                    <Typography variant="p" fontSize={13} marginLeft="5px">
                      {selectedMembers?.mobile_number}
                    </Typography>
                  </Box>
                  {/* Aadhaar Number */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" fontSize={14}>
                      Aadhaar Number:{" "}
                    </Typography>
                    <Typography variant="p" fontSize={13} marginLeft="5px">
                      {selectedMembers?.aadhaar_number}
                    </Typography>
                  </Box>
                  {/* Pan Number */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" fontSize={14}>
                      Pan Number:{" "}
                    </Typography>
                    <Typography variant="p" fontSize={13} marginLeft="5px">
                      {selectedMembers?.pancard_number}
                    </Typography>
                  </Box>
                  {/* Address */}
                  <Box display="flex">
                    <Typography variant="h6" fontSize={14} alignSelf="start">
                      Address:{" "}
                    </Typography>
                    <Typography
                      variant="p"
                      fontSize={13}
                      marginLeft="5px"
                      alignSelf="center"
                    >
                      {selectedMembers?.address}
                    </Typography>
                  </Box>
                  {/* Pincode */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" fontSize={14}>
                      Pincode:{" "}
                    </Typography>
                    <Typography variant="p" fontSize={13} marginLeft="5px">
                      {selectedMembers?.pincode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
        {/* Bank Details */}
        <Grid item md={6} xs={12} className="member-details-grid">
          <Box>
            <Paper
              sx={{ minHeight: "280px", p: "20px" }}
              className="member-details-paper"
            >
              <Typography
                variant="h5"
                fontSize={18}
                fontWeight={900}
                marginBottom="5px"
              >
                Bank Details
              </Typography>
              <Divider sx={{ backgroundColor: "#DC3545" }} />
              <Box marginTop="20px">
                {/* Text content */}
                {/* Member Name */}
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" fontSize={14}>
                    Bank Name:
                  </Typography>
                  <Typography variant="p" fontSize={13} marginLeft="5px">
                    {selectedMembers?.bank_detail[0]?.bank_name}
                  </Typography>
                </Box>
                {/* YCF ID */}
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" fontSize={14}>
                    Account Number:
                  </Typography>
                  <Typography variant="p" fontSize={13} marginLeft="5px">
                    {selectedMembers?.bank_detail[0]?.bank_account_number}
                  </Typography>
                </Box>
                {/* Aadhaar Number */}
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" fontSize={14}>
                    IFSC Code:
                  </Typography>
                  <Typography variant="p" fontSize={13} marginLeft="5px">
                    {selectedMembers?.bank_detail[0]?.ifsc_code}
                  </Typography>
                </Box>
                {/* Pan Number */}
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" fontSize={14}>
                    Branch:
                  </Typography>
                  <Typography variant="p" fontSize={13} marginLeft="5px">
                    {selectedMembers?.bank_detail[0]?.bank_branch_name}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>

        {/* Child Details */}
        <Grid item md={6} xs={10} className="member-details-grid">
          <Box>
            <Paper
              sx={{ minHeight: "280px", p: "20px" }}
              className="member-details-paper"
            >
              <Typography
                variant="h5"
                fontSize={18}
                fontWeight={900}
                marginBottom="5px"
              >
                Children
              </Typography>
              <Divider sx={{ backgroundColor: "#DC3545" }} />
              <Box marginTop="20px" height="215px">
                <ChildrenGrid
                  rows={selectedMembers?.children}
                  columns={columns}
                  disableSelectionOnClick
                  getRowHeight={() => "auto"}
                  className={styles.childrentable}
                />
              </Box>
            </Paper>
          </Box>
        </Grid>

        <Grid item md={6} xs={12} >
        <Box>
            <Paper
              sx={{ minHeight: "280px", p: "20px" }}
              className="member-details-paper"
            >
              <Typography
              variant="h5"
              fontSize={18}
              fontWeight={900}
              marginBottom="5px"
            >
              Member Benefits
            </Typography>
          
            <Divider sx={{ paddingLeft: 3, backgroundColor: "#DC3545" }} />
            <TableContainer sx={{marginTop: "20px"}} component={Paper} >
            

            <Table paddingLeft="20px" sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Benefit</TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell align="left">Balance</TableCell>
                  <TableCell align="left">Frequency</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  console.log(item);
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="left">{item.title}</TableCell>
                      <TableCell align="left">{item.total}</TableCell>
                      <TableCell align="left">{item.balance}</TableCell>
                      <TableCell align="left">{item.frequency}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

              </Paper></Box>
          
        </Grid>

       
  
        {/* Transactions Details */}
        <Grid item md={12} xs={12} className="member-details-grid">
          <Box>
            <Paper sx={{ minHeight: "500px", p: "20px" }}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="h5"
                  fontSize={18}
                  fontWeight={900}
                  marginBottom="5px"
                >
                  Transactions
                </Typography>
                <Box display="flex">
                  <Box display="flex">
                    <Typography variant="h6" fontSize={15}>
                      Balance:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight={400}
                      fontSize={15}
                      paddingLeft="3px"
                    >
                      {selectedMembers?.balance_amount}
                    </Typography>
                  </Box>
                  <Box display="flex" marginLeft="20px">
                    <Typography variant="h6" fontSize={15}>
                      Yearly Quota:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight={400}
                      fontSize={15}
                      paddingLeft="3px"
                    >
                      {selectedMembers?.yearly_quota}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ backgroundColor: "#DC3545" }} />
              <Box marginTop="20px" height="450px">
                <DataGrid
                  rows={selectedMembers?.member_bank_transaction}
                  columns={transactionsColumn}
                  disableSelectionOnClick
                  components={{ Toolbar: CustomToolbar }}
                  getRowHeight={() => "auto"}
                  density="compact"
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                />
              </Box>
            </Paper>
            <Grid item md={6} xs={12} mt={2}>
              <Paper sx={{ minHeight: "500px", p: "20px" }}>
                <Box>
                  <Typography
                    variant="h5"
                    fontSize={18}
                    fontWeight={900}
                    marginBottom="5px"
                  >
                    Insurance Card
                  </Typography>
                  <Divider sx={{ backgroundColor: "#DC3545" }} />
                </Box>
                <Box mt={2}>
                  {insuranceImage && (
                    <Button
                      variant="outlined"
                      onClick={handleInsuranceDownload}
                    >
                      {/* <a hidden href={insuranceImage} target="_blank">View Insurance Card</a> */}
                      View Insurance Card
                    </Button>
                  )}

                  <Box display="flex" flexDirection="column" width="30%">
                    <Button
                      variant="outlined"
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      sx={{ margin: "10px 0" }}
                    >
                      <input
                        hidden
                        accept="application/pdf"
                        type="file"
                        onChange={handleImageSelect}
                      />
                      Select PDF
                    </Button>
                    {insuranceImageLocal && (
                      <Typography>
                        <b>File Name:</b> {insuranceImageLocal.name}
                      </Typography>
                    )}
                    <Button
                      variant="outlined"
                      onClick={handleInsuranceUpload}
                      sx={{ margin: "10px 0" }}
                    >
                      Upload
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MemberDetails;

