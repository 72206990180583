import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from "react-toastify";
import { SERVERLINK } from '../../config/config';

// const backendURL = 'http://localhost:8080'

export const userLogin = createAsyncThunk(
  'user/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${SERVERLINK}/api/auth/admin/sign_in`,
        { email, password },
        config
      )


      toast.success("Successfully Loggedin");
      // store user's token in local storage
      localStorage.setItem('userToken', data.access_token)
      localStorage.setItem('userInfo', JSON.stringify(data))
      
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message)
      } else {
        toast.error(error.message)
        return rejectWithValue(error.message)
      }
    }
  }
)

export const registerUser = createAsyncThunk(
  'user/register',
  async ({ firstName, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      await axios.post(
        `${SERVERLINK}/api/user/register`,
        { firstName, email, password },
        config
      )
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
