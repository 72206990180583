import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MembersTable from "../../components/Beneficiary/MembersTable";
import AddMember from "../../components/Beneficiary/AddMember";
import DeletedMembersTable from "../../components/Beneficiary/DeletedMembersTable";
import MemberDetails from "../MemberDetails/MemberDetails";
import { setSelectedMember } from "../../features/beneficiary/beneficiarySlice";
import "./beneficiary.css";

const Beneficiary = () => {
  const [beneficiaryMenu, setBeneficiary] = useState({
    members: true,
    addMember: false,
    deletedMember: false,
    showMemberDetails: false,
  });

  const dispatch = useDispatch();

  const resetMenu = () => {
    setBeneficiary({
        members: false,
        addMember: false,
        deletedMember: false,
        showMemberDetails: false,
    })
  }


  // reset SelectedMember in redux store if the currently active
  // menuitem is addMember 
  const resetSelectedMember = () => {
    for(let key in beneficiaryMenu){
      if(key == "addMember" && beneficiaryMenu[key] == true){
        dispatch(setSelectedMember(null));
      }
    }
  }

  const BeneficiaryMenuNavigator = (menuToNavigate) => {
    resetSelectedMember();
    resetMenu();
    setBeneficiary((prevValue) => {
      return {
          ...prevValue,
          [menuToNavigate]: true,
      }
  })
  } 

  return (
    <>
    {!beneficiaryMenu.showMemberDetails && (
      <Box className="beneficiary-button-container">
        <Button
          variant={beneficiaryMenu.members ? "contained" : "outlined"}
          sx={{ m: "0 10px 0 0" }}
          className="beneficiary-submenu-button"
          id="members"
          onClick={() => BeneficiaryMenuNavigator("members")}
        >
          Members
        </Button>
        <Button variant={beneficiaryMenu.addMember ? "contained" : "outlined"} sx={{ m: "0 10px 0 10px" }} className="beneficiary-submenu-button" id="addMember" onClick={() => BeneficiaryMenuNavigator("addMember")}>
          Add Members
        </Button>
        <Button variant={beneficiaryMenu.deletedMember ? "contained" : "outlined"} sx={{ m: "0 0 0 10px" }} className="beneficiary-submenu-button" id="deletedMember" onClick={() => BeneficiaryMenuNavigator("deletedMember")}>
          Deleted Members
        </Button>
    </Box>
    )}
      {/* <ShowPosts isShowPostModalOpen={openShowPostModel} closeShowPostModel={closeShowPostModel} />
    <AddPost isEditPostModalOpen={openEditPostModel} closeEditPostModel={closeEditPostModel} />  */}
      {/* TODO: Show spinner while data is loading */}
      {/* {posts ? (*/}
      {beneficiaryMenu.members && <MembersTable BeneficiaryMenuNavigator={BeneficiaryMenuNavigator}/>}
      {beneficiaryMenu.addMember && <AddMember BeneficiaryMenuNavigator={BeneficiaryMenuNavigator}/>}
      {beneficiaryMenu.deletedMember && <DeletedMembersTable />}
      {beneficiaryMenu.showMemberDetails && <MemberDetails BeneficiaryMenuNavigator={BeneficiaryMenuNavigator}/>}
    </>
  );
};

export default Beneficiary;
