import { awsS3Config, SERVERLINK } from "../config/config";
import AWS from "aws-sdk";
import axios from "axios";

/**
 * Digital Ocean Spaces Connection
 */
// https://mysaathi.sgp1.digitaloceanspaces.com

const spacesEndpoint = new AWS.Endpoint("sgp1.digitaloceanspaces.com");
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "DO00UH9JTMY33GAMTVQA",
  secretAccessKey: "Ceg0zECMJfcLwdfGssgqwR8ce2C+0iIRYkP/DwXrfh0",
});

export const uploadFeaturedImage = async (image_to_upload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${SERVERLINK}/api/fileupload/featured_image`;
      const formData = new FormData();
      formData.append("upload", image_to_upload);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      let image = await axios.post(url, formData, config);
      resolve(image)
    } catch (err) {
      console.log(err);
      reject(err)
    }
  });
};

export const uploadInsurance = async (image_to_upload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${SERVERLINK}/api/fileupload/upload_insurance`;
      const formData = new FormData();
      formData.append("upload", image_to_upload);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      let image = await axios.post(url, formData, config);
      resolve(image)
    } catch (err) {
      console.log(err);
      reject(err)
    }
  });
};

// uploads file to the featured folder on s3 bucket
export const uploadMemberProfilePhoto = async (image_to_upload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${SERVERLINK}/api/fileupload/upload_member_photo`;
      const formData = new FormData();
      formData.append("upload", image_to_upload);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      let image = await axios.post(url, formData, config);
      resolve(image)
    } catch (err) {
      console.log(err);
      reject(err)
    }
  });
};

// uploads file to the notification folder on s3 bucket
export const uploadNotificationImage = async (image_to_upload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${SERVERLINK}/api/notification/upload_notification_image`;
      const formData = new FormData();
      formData.append("upload", image_to_upload);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      let image = await axios.post(url, formData, config);
      resolve(image)
    } catch (err) {
      console.log(err);
      reject(err)
    }
  });
};
