import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#DC3545",
    },
    secondary: {
      main: "#949494",
    },
  },
});