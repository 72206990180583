import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  posts: [],
  selectedPost: null,
  error: null,
  success: false,
}

const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    setPosts: (state, {payload}) => {
      state.posts = payload
    },
    setSelectedPost: (state, {payload}) => {
      state.selectedPost = payload
    }
  },
})

export const { setPosts, setSelectedPost } = archiveSlice.actions

export default archiveSlice.reducer;
