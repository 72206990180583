import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import ListItem from "@mui/material/ListItem";
import { ListItemButton, ListItemIcon, ListItemText} from "@mui/material"
import { FormatAlignJustify } from '@mui/icons-material';

const LeftNavBarButton = ({linkTo, Icon, label, onClick = ""}) => {
  const location = useLocation()
  return (
    <Link to={linkTo} onClick={onClick} className="link-style">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Icon
                  className={
                    location.pathname === linkTo
                      ? "main-menu-active"
                      : ""
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={label}
                className={
                  location.pathname === linkTo
                    ? "main-menu-active"
                    : ""
                }
              />
            </ListItemButton>
          </ListItem>
        </Link>
  )
}

export default LeftNavBarButton