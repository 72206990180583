import { buildCreateApi } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../app/services/auth/apiSlice";

export const aboutApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // createFaqs: builder.mutation({
        //     query: data => ({
        //         url: '/api/faqs/create_faqs',
        //         method: 'POST',
        //         body: { ...data }
        //     })
        // }),
        // deleteFaqs: builder.mutation({
        //     query: data => ({
        //         url: 'api/faqs/delete_faqs',
        //         method: 'DELETE',
        //         body: { ...data }
        //     })
        // }),
         updateAbout: builder.mutation({
            query: data => ({
                url: '/api/about_us/update_about_us',
                method: 'POST',
                body: { ...data }
            })
        }),
        
         getAboutByLanguage: builder.mutation({
            query: data => ({
                url: '/api/about_us/get_about_us_by_language',
                method: 'POST',
                body: { ...data }
            })
         }),
    })
})

export const {
           useUpdateAboutMutation,
           useGetAboutByLanguageMutation
           
} = aboutApiSlice;