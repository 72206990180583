import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import { useDeleteDashboardUserMutation, useGetAllDashboardUsersMutation } from "../../features/dashboardUserManagement/dashboardUserManagementApiSlice";
import { setDashboardUser, setSelectedDashboardUser } from "../../features/dashboardUserManagement/dashboardUserManagementSlice";
import Profile from "../profile/Profile";
import AddDashboardUser from "../../components/DashboardUserManagement/AddDashboardUser";
import useMediaQuery from '@mui/material/useMediaQuery';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    border: "0",
    borderRadius: "5px",
    boxShadow: 24,
    overflow: "scroll",
    pt: 2,
    px: 4,
    pb: 3,
    m: "20px 0",
  };
  

const DashboardUserManagement = ({ BeneficiaryMenuNavigator }) => {
  const [addButton, setAddButton] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]); // holds all the selected post
  const [openModal, setOpenModal] = React.useState(false);
  const [editOption, setEditOption] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const dispatch = useDispatch();

  const [getAllDashboardUsers] = useGetAllDashboardUsersMutation();
  const [deleteDashboardUser] = useDeleteDashboardUserMutation();

  const allDashboardUsersList = useSelector(
    (state) => state.dashboardUser.userList
  );

  useEffect(() => {
    fetchAllDashboardUsers();
  }, [openModal]);

  // get all the members from the server who are not trashed
  const fetchAllDashboardUsers = async () => {
    const dashboardUsersList = await getAllDashboardUsers().unwrap();
    console.log(dashboardUsersList);
    if (dashboardUsersList.status === 500) {
      toast.error("Internal Server Erorr");
      return;
    }
    dispatch(setDashboardUser(dashboardUsersList.data));
  };

  const setOptionClicked = (optionSelected) => {
    setEditOption(true)
  }

  const columns = [
    { field: "id", headerName: "ID", minWidth: 160, },
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 160,
      // sortable: false,
    },
    { field: "last_name", headerName: "Last Name", flex: 1, minWidth: 160 },
    { field: "email_id", headerName: "Email Id", flex: 1, minWidth: 200 },
    { field: "role", headerName: "Role", flex: 1, minWidth: 165 },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 140,
      filterable: false,
      sortable:false,
      renderCell: (param) => (
        <Box width="100%">

          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
                setOptionClicked("viewOption")
                setOpenModal(true);
                dispatch(setSelectedDashboardUser(param.row));
            //   BeneficiaryMenuNavigator("addMember");
            }}
          >
            <EditIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
          <IconButton
            sx={{ width: "40px" }}
            onClick={async () => {
              const selectedIDs = new Set([param.row.id]);
              let filteredMembers = allDashboardUsersList.filter(
                (x) => !selectedIDs.has(x.id)
              );
              let deleteIdArray = {
               id: [param.row.id]
              }
              try{
                const result = await deleteDashboardUser(deleteIdArray);
                if (result !== "error") {
                  dispatch(setDashboardUser(filteredMembers));
                  toast.success("Deleted Memeber")
                  return;
                }
                return;
              }catch(err) {
                toast.error("Internal Server Error")
              }
             
            }}
          >
            <DeleteIcon
              sx={{
                width: "20px",
                color: "#ba000d",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {/* When user selects some data then only display it */}
        {selectionModel.length > 0 && (
          <DeleteAllButton
            selectionModel={selectionModel}
            deleteDashboardUser={deleteDashboardUser}
            dashboardUsersList={allDashboardUsersList}
            dispatch={dispatch}
          />
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <>
      {/* TODO: Show spinner while data is loading */}
      {/* {posts ? (*/}
      <Box>
      <Modal
        open={openModal}
        onClose={() => {
            setOpenModal(false)
            dispatch(setSelectedDashboardUser(null))
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width: isMobile ? "90%" : ""}}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" onClick={() => {
                setOpenModal(false)
                setEditOption(false);
                setAddButton(false);
                dispatch(setSelectedDashboardUser(null))
            }}>
              Close
            </Button>
          </Box>
          {editOption && <Profile /> }
          {addButton && <AddDashboardUser />}
        </Box>
      </Modal>
        <Box marginBottom={1}>
          <Button
            variant={addButton ? "contained" : "outlined"}
            onClick={() => {
              setAddButton(true);
              setOpenModal(true);
              //TODO open add user panel
            }}
          >
            Add User
          </Button>
        </Box>

        <Box
          sx={{
            height: "80vh",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
          {allDashboardUsersList && (
            <DataGrid
              rows={allDashboardUsersList}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'id', sort: 'asc' }],
                },
              }}
              checkboxSelection
              disableSelectionOnClick
              density="compact"
              components={{ Toolbar: CustomToolbar }}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default DashboardUserManagement;

const DeleteAllButton = ({
  selectionModel,
  deleteDashboardUser,
  dashboardUsersList,
  dispatch,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: "10px",
      }}
    >
      <IconButton
        onClick={async () => {
          if (selectionModel.length === 0) {
            toast.error("Please select atleast 1 post to delete");
            return;
          }
          const selectedIDs = new Set(selectionModel);
          let filteredMembers = dashboardUsersList.filter(
            (x) => !selectedIDs.has(x.id)
          );

          let deleteIdArray = {
            id: selectionModel
           }
           try{
            const result = await deleteDashboardUser(deleteIdArray);
            if (result !== "error") {
              dispatch(setDashboardUser(filteredMembers));
              toast.success("Successfully deleted Users")
              return;
            }
            return;
           }catch(err){
            toast.error("Internal Server Error");
           }
         
        }}
      >
        <DeleteIcon color="primary" />
        
      </IconButton>
    </Box>
  );
};
