import { buildCreateApi } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../app/services/auth/apiSlice";

export const publisherApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        publishArticle: builder.mutation({
            query: data => ({
                url: '/api/news/create_news',
                method: 'POST',
                body: { ...data }
            })
        }),
        publishEnglishArticle: builder.mutation({
            query: data => ({
                url: '/api/news/create_english_news',
                method: 'POST',
                body: { ...data }
            })
        }),
        publishHindiArticle: builder.mutation({
            query: data => ({
                url: '/api/news/create_hindi_news',
                method: 'POST',
                body: { ...data }
            })
        }),
        publishMarathiArticle: builder.mutation({
            query: data => ({
                url: '/api/news/create_marathi_news',
                method: 'POST',
                body: { ...data }
            })
        }),
    })
})

export const {
    usePublishArticleMutation,
    usePublishEnglishArticleMutation,
    usePublishHindiArticleMutation,
    usePublishMarathiArticleMutation
} = publisherApiSlice;