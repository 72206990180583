import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SERVERLINK } from '../../../config/config';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVERLINK,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken
      console.log("token " + token);
      if (token) {
        headers.set('x-access-token', token)
        return headers
      }
    },
  }),
  endpoints: (build) => ({
    getDetails: build.query({
      query: () => ({
        url: 'api/user/profile',
        method: 'GET',
      }),
    }),
  }),
})




// export react hook
export const { useGetDetailsQuery } = authApi
