import { buildCreateApi } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../app/services/auth/apiSlice";

export const tutorialApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createTutorial: builder.mutation({
            query: data => ({
                url: '/api/tutorials/add_tutorials',
                method: 'POST',
                body: { ...data }
            })
        }),
        //https://saathidev.anujnirmal.com/api/tutorials/get_tutorials_by_language
        deleteTutorial: builder.mutation({
            query: data => ({
                url: 'api/tutorials/delete_tutorials',
                method: 'DELETE',
                body: { ...data }
            })
        }),
         updateTutorial: builder.mutation({
            query: data => ({
                url: 'api/tutorials/update_tutorials',
                method: 'PUT',
                body: { ...data }
            })
        }),
        
         getTutorialByLanguage: builder.mutation({
            query: data => ({
                url: 'api/tutorials/get_tutorials_by_language',
                method: 'POST',
                body: { ...data }
            })
        }),
    })
})
export const {
    useCreateTutorialMutation, 
    useGetTutorialByLanguageMutation,
    useDeleteTutorialMutation,
    useUpdateTutorialMutation
}
= tutorialApiSlice;

// export const {
//     useCreateFaqsMutation,
//     useDeleteFaqsMutation,
//     useGetFaqsByLanguageMutation, 
//     useUpdateFaqsMutation
// } = faqsApiSlice;