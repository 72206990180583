import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {theme} from "../../theme";
import {
  deletePosts,
  setPosts,
  setSelectedPost,
} from "../../features/archive/archiveSlice";
import {
  useGetPostsMutation,
  useDeletePostsMutation,
} from "../../features/archive/archiveApiSlice";
import ShowPosts from "../ShowPosts";
import { toast } from "react-toastify";
import AddPost from "../EditPost";
import {
  useDeleteMembersMutation,
  useGetAllMembersMutation,
} from "../../features/beneficiary/beneficiaryApiSlice";
import { setMembers, setSelectedMember } from "../../features/beneficiary/beneficiarySlice";

const ClinicTable = ({handleShowMemberDetails, BeneficiaryMenuNavigator}) => {
  const [selectionModel, setSelectionModel] = React.useState([]); // holds all the selected post

  const dispatch = useDispatch();

  const [getAllMembers] = useGetAllMembersMutation();
  const [deleteMembers] = useDeleteMembersMutation();

  useEffect(() => {
    fetchAllMembers();
  }, []);

  // get all the members from the server who are not trashed
  const fetchAllMembers = async () => {
    const members = await getAllMembers().unwrap();
    console.log(members);
    if (members.status === 500) {
      toast.error("Internal Server Erorr");
      return;
    }
    dispatch(setMembers(members.data));
  };

  const membersList = useSelector((state) => state.beneficiary.members);

  const deleteBeneficiaryMembers = async (memberIds) => {
    try {
      let membersToDelete = {
        members_id: memberIds,
      };
      const result = await deleteMembers(membersToDelete).unwrap();
      toast.success(
        "Successfully deleted " + selectionModel.length + " members"
      );
      // clearing the state
      setSelectionModel([]);
      return "success";
    } catch (err) {
      console.log(err);
      if (err.status === 404) {
        toast.error("Please select atleast one member");
        return "error";
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      filterable: false,
      renderCell: (param, index) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <img src={param.value} style={{ height: "100%" }} />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: "Full Name",
      // sortable: false,
    },
    { field: "mobile_number", headerName: "Mobile Number", flex: 1, minWidth: 140},
    { field: "aadhaar_number", headerName: "Aadhaar Number", flex: 1, minWidth: 140 },
    { field: "modules", headerName: "Benefits", flex: 1, minWidth: 165 },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (param) => (
        <Box width="100%">
          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              dispatch(setSelectedMember(param.row));
              BeneficiaryMenuNavigator("showMemberDetails");
            }}
          >
            <VisibilityIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>

          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              dispatch(setSelectedMember(param.row));
              BeneficiaryMenuNavigator("addMember");
            }}
          >
            <EditIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
          <IconButton
            sx={{ width: "40px" }}
            onClick={async () => {
              const selectedIDs = new Set([param.row.id]);
              let filteredMembers = membersList.filter(
                (x) => !selectedIDs.has(x.id)
              );
              const result = await deleteBeneficiaryMembers([param.row.id]);
              if (result != "error") {
                dispatch(setMembers(filteredMembers));
                return;
              }
              return;
            }}
          >
            <DeleteIcon
              sx={{
                width: "20px",
                color: "#ba000d",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {/* When user selects some data then only display it */}
        {selectionModel.length > 0 && (
          <DeleteAllButton
            selectionModel={selectionModel}
            deleteBeneficiaryMembers={deleteBeneficiaryMembers}
            membersList={membersList}
            dispatch={dispatch}
          />
         )} 
      </GridToolbarContainer>
    );
  }

  return (
    <>
      {/* TODO: Show spinner while data is loading */}
      {/* {posts ? (*/}
      <Box
        sx={{
          height: "80vh",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        <DataGrid
          rows={membersList}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          density="compact"
          components={{ Toolbar: CustomToolbar }}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
        />
      </Box>
    </>
  );
};

export default ClinicTable;

const DeleteAllButton = ({
  selectionModel,
  deleteBeneficiaryMembers,
  membersList,
  dispatch,
}) => {
  return (
  <Box
    sx={{
      position: "absolute",
      right: "10px"
    }}
  >
    <IconButton
      onClick={async () => {
        if (selectionModel.length === 0) {
          toast.error("Please select atleast 1 post to delete");
          return;
        }
        const selectedIDs = new Set(selectionModel);
        let filteredMembers = membersList.filter((x) => !selectedIDs.has(x.id));
        const result = await deleteBeneficiaryMembers(selectionModel);
        if (result != "error") {
          dispatch(setMembers(filteredMembers));
          return;
        }
        return;
      }}
    >
      <DeleteIcon color="primary"/>
    </IconButton>
  </Box>
  )
};
