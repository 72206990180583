import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  userList: [],
  selectedDashboardUser: null,
  error: null,
  success: false,
};

const dashboardUserManagementSlice = createSlice({
  name: "dashboarduser",
  initialState,
  reducers: {
    setDashboardUser: (state, { payload }) => {
      state.userList = payload;
    },
    setSelectedDashboardUser: (state, { payload }) => {
      state.selectedDashboardUser = payload;
    },
  },
});

export const { setDashboardUser, setSelectedDashboardUser } =
  dashboardUserManagementSlice.actions;

export default dashboardUserManagementSlice.reducer;

