import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import RedeemIcon from "@mui/icons-material/Redeem";
import SchoolIcon from "@mui/icons-material/School";
import HomeIcon from "@mui/icons-material/Home";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PublishIcon from "@mui/icons-material/Publish";
import ArchiveIcon from "@mui/icons-material/Archive";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import useMediaQuery from "@mui/material/useMediaQuery";
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Faqs from "../faqs/Faqs.jsx"
import Publisher from "../publisher/Publisher";
import Archive from "../archive/Archive";
import { logout } from "../../features/auth/authSlice";
import "./dashboard.css";
import Beneficiary from "../beneficiary/Beneficiary";
import Logo from "../../assets/images/logo.png";
import Education from "../education/Education";
import Medical from "../medical/Medical";
import Profile from "../profile/Profile";
import DashboardUserManagement from "../DashboardUserManagement/DashboardUserManagement";
import { setSelectedDashboardUser } from "../../features/dashboardUserManagement/dashboardUserManagementSlice";
import { useSeedDataMutation } from "../../features/beneficiary/beneficiaryApiSlice";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Analytics from "../analytics/Analytics";
import { toast } from "react-toastify";
import { FormatAlignJustify } from "@mui/icons-material";
import LeftNavBarButton from "../../components/common/LeftNavBarButton";
import NewApplication from "../applications/NewApplication";
import Tutorial from "../tutorials/Tutorial.jsx";
import About from "../about/About.jsx";


const drawerWidth = 240;

function Dashboard(props) {
  const { window } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const loggedInUser = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  const [seedData] = useSeedDataMutation();
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleProfileClick = () => {
    console.log("log", loggedInUser);
    dispatch(setSelectedDashboardUser(loggedInUser));
  };

  const handleSeedData = async () => {
    try {
      const body = {
        "admin_name": "server",
        "admin_id": 1
      }
      const result = await seedData(body).unwrap();
      toast.success("Seeded Successfully!")
    } catch (error) {
      toast.error("Please check seeding data");
    }
  }

  const drawer = (
    <div>
      <Toolbar>
        {isMobile ? null : (
          <Box display="flex" alignItems="center">
            <img src={Logo} alt="MySaathi logo" width="20%" />
            <Typography typography="h6" padding="0 0 0 5px" fontSize={15}>
              MySaathi Dashboard
            </Typography>
          </Box>
        )}
      </Toolbar>
      <List>
        <Divider />
        <Typography fontSize={12} margin={"10px 10px"} paragraph>
          Beneficiary Management
        </Typography>
          <LeftNavBarButton linkTo={"/"} Icon={PersonIcon} label={"Beneficiaries"}/> 
          <LeftNavBarButton linkTo={"/applications"} Icon={FormatAlignJustify} label={"Applications"}/> 
        <Divider />

        <Typography fontSize={12} margin={"10px 10px"} paragraph>
          Programme Management
        </Typography>
        <LeftNavBarButton linkTo={"/education"} Icon={SchoolIcon} label={"Education"}/> 
        <Divider />

        <Typography fontSize={12} margin={"10px 10px"} paragraph>
          Awareness Module
        </Typography>
        
        <LeftNavBarButton linkTo={"/publisher"} Icon={PublishIcon} label={"Publisher"}/> 
        <LeftNavBarButton linkTo={"/archive"} Icon={ArchiveIcon} label={"Archive"}/> 
        <LeftNavBarButton linkTo={"/faqs"} Icon={QuestionAnswerRoundedIcon} label={"FAQs"}/>
        <LeftNavBarButton linkTo={"/tutorial"} Icon={OndemandVideoRoundedIcon} label={"Tutorials"}/>
        <LeftNavBarButton linkTo={"/about"} Icon={InfoRoundedIcon} label={"About"}/>

        {loggedInUser.role === "SUPERADMIN" && (
          <Box>
            <Typography fontSize={12} margin={"10px 10px"} paragraph>
              Dashboard Management
            </Typography>
              <LeftNavBarButton linkTo={"/profile"} Icon={AccountCircleIcon} label={"My Profile"} onClick={handleProfileClick} />
              <LeftNavBarButton linkTo={"/dashboardUserManagement"} Icon={ManageAccountsIcon} label={"User Management"} />
            <Divider />
            <LeftNavBarButton linkTo={"/analytics"} Icon={AnalyticsIcon} label={"Analytics"} />
          </Box>
        )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex"}}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {isMobile ? (
            <Box display="flex" alignItems="center">
              <img src={Logo} alt="MySaathi logo" width="20%" />
              <Typography
                typography="h6"
                noWrap
                padding="0 0 0 5px"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "205px",
                  fontSize: "15px",
                }}
              >
                MySaathi Dashboard
              </Typography>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="end" width="100%">
            <Box>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <AccountCircleIcon
                    sx={{ color: "white", width: 32, height: 32 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  {/* <Avatar />  */}
                  {loggedInUser.email}
                </MenuItem>
                <MenuItem onClick={() => handleSeedData()}>
                  Seed Data
                </MenuItem>
                <MenuItem onClick={() => handleLogout()}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Beneficiary />} index={true} />
          <Route path="/applications" element={<NewApplication />} />
          <Route path="/education" element={<Education />} />
          <Route path="/publisher" element={<Publisher />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/medical" element={<Medical />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/faqs" element={<Faqs/>} />
          <Route path="/tutorial" element={<Tutorial/>} />
          <Route path="/about" element={<About/>} />
          

          {/* SUPER ADMIN ONLY ROUTES */}
          {loggedInUser.role === "SUPERADMIN" && (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/dashboardUserManagement"
                element={<DashboardUserManagement />}
              />
            </>
          )}
        </Routes>
      </Box>
    </Box>
  );
}

Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Dashboard;
