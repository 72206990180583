import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Divider,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
  Button,
  Modal,
  FormLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import {
  setPendingTransaction,
  setSelectedMember,
} from "../../features/beneficiary/beneficiarySlice";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import {
  useAcceptAmountMutation,
  useAcceptTransactionMutation,
  useRejectTransactionMutation,
} from "../../features/transaction/transactionApiSlice";
import "./viewreceiptandaccept.css";
import { setSelectedTransaction } from "../../features/transaction/transactionSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0",
  borderRadius: "5px",
  boxShadow: 24,
  overflow: "scroll",
  p: 2,
  m: "20px 0",
};

const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const ViewReceiptAndAccept = ({
  pendingMembers,
  setOpenShowReceiptModule,
  confirmationScreen,
  setCounter,
  counter,
}) => {
  const { selectedPendingTransaction, pendingTransactions } = useSelector(
    (state) => state.transaction
  );
  const { userInfo } = useSelector((state) => state.auth);
  const [requestedAmount, setRequestedAmount] = React.useState("");
  const [remarks, setRemarks] = React.useState("");
  const [amountErrorText, SetAmountErrorText] = React.useState();
  const [receiptImageUrl, setReceiptImageUrl] = React.useState("");
  const [openViewReceipt, setOpenViewReceipt] = React.useState(false);

  const dispatch = useDispatch();
  const [acceptTransaction] = useAcceptTransactionMutation();
  const [rejectTransaction] = useRejectTransactionMutation();
  const [acceptAmount] = useAcceptAmountMutation();

  const receiptsColumn = [
    { field: "id", headerName: "Receipt ID", flex: 1 },
    {
      field: "upload_date",
      headerName: "Upload Date",
      flex: 1,
      renderCell: (param) => {
        let UTCDate = new Date(param?.row?.upload_date);
        let date =
          UTCDate.getDate() +
          "/" +
          months[UTCDate.getMonth()] +
          "/" +
          UTCDate.getFullYear();
        return date;
      },
    },
    {
      field: "options",
      headerName: "Option",
      flex: 1,
      renderCell: (param) => (
        <Box>
          <IconButton
            onClick={() => {
              setReceiptImageUrl(param?.row?.receipt_link);
              setOpenViewReceipt(true);
              // window.open(param.row?.receipt_link, "_blank");
            }}
          >
            <VisibilityIcon />
          </IconButton>
          {/* <IconButton
            onClick={() => {
              downloadReceiptImage(param.row.receipt_link);
            }}
          >
            <DownloadIcon />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  // TODO: Set the object when the pending object is selected

  const downloadReceiptImage = (link) => {
    const url = link;
    const options = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    fetch(url, options).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        let lastIndex = url.lastIndexOf(".");
        let extension = url.substring(lastIndex + 1);
        a.download = "Receipt" + extension;
        a.click();
      });
    });
  };

  // statusToChange = reject, accept
  const handleRedemption = async (statusToChange) => {
    // Check with the balance
    try {
      let result;
      if (confirmationScreen !== true) {
        if (requestedAmount.length === 0) {
          return toast.error("Please enter the amount");
        }
        if (statusToChange === "accept") {
          let transactionRequest = {
            member_id: selectedPendingTransaction[0]?.member?.id,
            transaction_id: selectedPendingTransaction[0]?.id,
            amount_requested: parseInt(requestedAmount),
            remarks: remarks,
          };
          result = await acceptAmount(transactionRequest).unwrap();
          var newPendingRequest = pendingTransactions;
          console.log("Pending", newPendingRequest)
          const updatedArray = newPendingRequest.map((transaction, index) => {
            if(transaction?.id === selectedPendingTransaction[0]?.id){
              return {
                ...transaction,
                amount_requested: requestedAmount,
                amount_updated: true,
                remarks: remarks
              };
            }else {
              return {
                ...transaction
              }
            }
          })
          setRequestedAmount("")
          dispatch(setPendingTransaction(updatedArray))
          setOpenShowReceiptModule(false);
          toast.success("Successfully updated amount")
          return;
        } else {
          let transactionRequest = {
            member_id: selectedPendingTransaction[0].member?.id,
            transaction_id: selectedPendingTransaction[0]?.id,
            amount_requested: parseInt(requestedAmount),
            admin_name: userInfo.first_name,
            admin_id: userInfo?.id,
            remarks: remarks
          };
          result = await rejectTransaction(transactionRequest).unwrap();
        }
      } else {
        console.log("Hye")
        if(selectedPendingTransaction[0]?.amount_requested === null || selectedPendingTransaction[0]?.amount_requested === undefined || selectedPendingTransaction[0]?.amount_requested === ""){
          toast.error("Please enter the amount first");
          return;
        }
        let transactionRequest = {
          member_id: selectedPendingTransaction[0].member?.id,
          transaction_id: selectedPendingTransaction[0]?.id,
          admin_name: userInfo.first_name,
          admin_id: userInfo?.id,
          remarks: remarks,
          amount_requested: selectedPendingTransaction[0]?.amount_requested
        };

        if (statusToChange === "accept") {
          console.log(statusToChange);
          result = await acceptTransaction(transactionRequest).unwrap();

        } else {
          result = await rejectTransaction(transactionRequest).unwrap();
        }
      }
      
      toast.success("Success");
      let newSelectedPendingRequests = pendingTransactions.filter(
        (x) => x.id != selectedPendingTransaction[0]?.id
      );
      setRequestedAmount(null);
      dispatch(setPendingTransaction(newSelectedPendingRequests));
      setOpenShowReceiptModule(false);
      setCounter(counter + 1);
    } catch (error) {
      console.log(error);
      if (error?.status === 422) {
        return toast.error(error?.data?.message);
      }
      toast.error("Error Accepting the transaction");
      return;
    }
  };

  const handleAmountChange = (e) => {
    setRequestedAmount(e.target.value);
  };

  return (
    <Box sx={style} className="view-receipt-and-accept">
      <Modal
        open={openViewReceipt}
        onClose={() => {
          // Close the modal
          setOpenViewReceipt(false);
          // remove the receipt image url
          setReceiptImageUrl("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-receipt-modal">
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              onClick={() => {
                // Close the modal
                setOpenViewReceipt(false);
                // remove the receipt image url
                setReceiptImageUrl("");
              }}
            >
              Close
            </Button>
          </Box>
          {/* Receipt Image will go here */}
          <img
            src={receiptImageUrl}
            alt="receipt"
            className="receipt-modal-image"
          />
        </Box>
      </Modal>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Member Details</Typography>
          <IconButton
            onClick={() => {
              dispatch(setSelectedMember(null));
              setOpenShowReceiptModule(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider></Divider>
        <Grid container spacing={2} marginTop="0">
          <Grid item xs={12}>
            <Box display="flex">
              <Typography fontWeight="bold" color="grey">
                YCF ID:
              </Typography>
              <Typography marginLeft="3px" color="grey">
                {selectedPendingTransaction &&
                  selectedPendingTransaction[0]?.member?.ycf_id}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold" color="grey">
                Name:
              </Typography>
              <Typography marginLeft="3px" color="grey">
                {selectedPendingTransaction &&
                  selectedPendingTransaction[0]?.member?.full_name}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold" color="grey">
                Phone Number:
              </Typography>
              <Typography marginLeft="3px" color="grey">
                {selectedPendingTransaction &&
                  selectedPendingTransaction[0]?.member?.mobile_number}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold" color="grey">
                Balance:
              </Typography>
              <Typography marginLeft="3px" color="grey">
                {selectedPendingTransaction &&
                  selectedPendingTransaction[0]?.member?.member_benefits[0]?.education_amount_balance}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" fontSize={17}>
              Receipts
            </Typography>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12}>
            <Box height="200px">
              {console.log(selectedPendingTransaction)}
              {selectedPendingTransaction ? (
                <DataGrid
                  rows={selectedPendingTransaction[0]?.receipts}
                  columns={receiptsColumn}
                  disableSelectionOnClick
                  getRowHeight={() => "auto"}
                  density="compact"
                />
              ) : null}
            </Box>
          </Grid>
          {!confirmationScreen && (
  
            <Grid item xs={12}>
              <Box>
                <FormLabel for="amountTo" sx={{ paddingBottom: "5px" }}>
                  Enter the amount to be dispersed:
                </FormLabel>
                <br />
                <TextField
                  id="amountTo"
                  name="amountTo"
                  label="Enter Amount"
                  variant="outlined"
                  type="number"
                  value={requestedAmount}
                  onChange={handleAmountChange}
                />
              </Box>
              <p className="error-text">
                {amountErrorText ? amountErrorText : null}
              </p>
            </Grid>
         
            
          )}

          <Grid item xs={12}>
              <Box>
                <TextField
                  id="remarks"
                  name="remarks"
                  label="Remarks(Optional)"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Box>
              <p className="error-text">
                {amountErrorText ? amountErrorText : null}
              </p>
            </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ marginRight: "5px" }}
              onClick={() => handleRedemption("accept")}
            >
              <CheckIcon />
              {confirmationScreen ? "Confirm Transaction" : "Update Amount"}
            </Button>
            <Button
              variant="contained"
              sx={{ marginLeft: "5px" }}
              onClick={() => handleRedemption("reject")}
            >
              <CloseIcon />
              Reject
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewReceiptAndAccept;
