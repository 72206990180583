import { buildCreateApi } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../app/services/auth/apiSlice";

export const memberBenefitsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({       
         getData: builder.mutation({
            query: data => ({
                url: '/api/member/get_member_benefits',
                method: 'POST',
                body: { ...data }
            })
         }),
    })
})

export const {
           useGetDataMutation
           
} = memberBenefitsApiSlice;