import { apiSlice } from "../../app/services/auth/apiSlice";

export const beneficiaryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addMember: builder.mutation({
            query: data => ({
                url: '/api/admin/create_member',
                method: 'POST',
                body: { ...data }
            })
        }),
        updateMember: builder.mutation({
            query: data => ({
                url: '/api/admin/update_member',
                method: 'PUT',
                body: { ...data }
            })
        }),
        getAllMembers: builder.mutation({
            query: () => ({
                url: '/api/admin/get_all_members',
                method: 'POST'
            })
        }),
        getAllDeletedMembers: builder.mutation({
            query: () => ({
                url: '/api/admin/get_all_deleted_members',
                method: 'POST'
            })
        }),
        deleteMembers: builder.mutation({
            query: data => ({
                url: '/api/admin/delete_members',
                method: 'DELETE',
                body: { ...data }
            })
        }),
        deleteChild: builder.mutation({
            query: data => ({
                url: '/api/admin/delete_child',
                method: 'DELETE',
                body: { ...data }
            })
        }),
        restoreMembers: builder.mutation({
            query: data => ({
                url: '/api/admin/restore_members',
                method: 'POST',
                body: { ...data }
            })
        }),
        updateMemberPassword: builder.mutation({
            query: data => ({
                url: '/api/admin/updat_member_password',
                method: 'PUT',
                body: { ...data }
            })
        }),
        seedData: builder.mutation({
            query: data => ({
                url: '/api/admin/seed_user_from_google_sheet',
                method: 'POST',
                body: { ...data }
            })
        }),
        addInsuranceLink: builder.mutation({
            query: data => ({
                url: '/api/admin/add_insurance_link',
                method: 'POST',
                body: { ...data }
            })
        }),
        permanentDeleteMembers: builder.mutation({
            query: data => ({
                url: '/api/admin/permanent_delete',
                method: 'DELETE',
                body: { ...data }
            })
        }),
    })
})

export const {
    useAddMemberMutation,
    useGetAllMembersMutation,
    useDeleteMembersMutation,
    useGetAllDeletedMembersMutation,
    useRestoreMembersMutation,
    useUpdateMemberMutation,
    useDeleteChildMutation,
    useUpdateMemberPasswordMutation,
    useSeedDataMutation,
    useAddInsuranceLinkMutation,
    usePermanentDeleteMembersMutation
} = beneficiaryApiSlice