import React, { useRef, useState } from "react";
import { Box, Typography, Button } from "@mui/material";

import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useUpdateAboutMutation,
  useGetAboutByLanguageMutation,
} from "../../features/about/aboutApiSlice";

window.Buffer = window.Buffer || require("buffer").Buffer;

const About = ({ closeEditPostModel }) => {
  const selectedPost = useSelector((state) => state.archive.selectedPost);

  const [content, setContent] = useState(selectedPost?.body);
  const [publishing, setPublishing] = useState(false);
  const [bodyPlainText, setBodyPlainText] = useState("");
  const [language, setLanguage] = useState(selectedPost?.language || "");
  const [aboutData, setAboutData] = useState("");
  const [updateAbout] = useUpdateAboutMutation();
  const [getBylanguage] = useGetAboutByLanguageMutation();

  const editor = useRef(null);

  //   //   toast.error("Please add a featured image");
  //   //   error = true;
  //   // }

  //   // if (title.length < 3) {
  //   //   toast.error("Title should be greater then 3 characters");
  //   //   error = true;
  //   // }

  //   return error;
  // };

  const handlePublishArticle = async () => {
    try {
      const result = await updateAbout({
        language: language,
        content: bodyPlainText,
      }).unwrap();

      setAboutData(result.data.content);
      toast.success(`Successfully updated ${language}`)
    } catch (e) {
      toast.error("Something went wrong !");
    }
  };

  const handleBodyChange = async (newContent) => {
    setBodyPlainText(newContent);
  };

  return (
    <div>
      <Box paddingTop={2}>
        <FormControl sx={{ width: "200px", margin: "1rem 0" }}>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="language-select"
            value={language}
            label="Language"
            onChange={async function (e) {
              try {
                const result = await getBylanguage({
                  language: e.target.value,
                }).unwrap();

                setAboutData(result.data.content);
                setLanguage(e.target.value);
              } catch (e) {
                toast.error("Something went wrong !");
              }
            }}
          >
            <MenuItem value={"selectLanguage"} disabled>
              Select Language
            </MenuItem>
            <MenuItem value={"English"}>English</MenuItem>

            <MenuItem value={"Hindi"}>Hindi</MenuItem>
            <MenuItem value={"Marathi"}>Marathi</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {
        language !== "" ? (

          <Box
            sx={{
              backgroundColor: "#fff",
             
              borderRadius: "5px",
            }}
          >
            {
              language == "" ? (
                ""
              ) : (
                <div>
                  <Box sx={{padding: 1}}>
                    <Typography
                      paddingTop={2}
                      variant="h5"
                      sx={{ margin: "0 0 5px 0" }}
                    >
                      {language == undefined ? `About` : `${language} About`}
                    </Typography>
                    <JoditEditor
                      ref={editor}
                      value={aboutData}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {
                        handleBodyChange(newContent);
                      }}
                    />

                    <br />

                    <Button
                      variant="contained"
                      sx={{ margin: "12px 0 6px 0" }}
                      onClick={handlePublishArticle}
                      disabled={publishing ? true : false}
                    >
                      Update
                    </Button>
                  </Box>
                </div>
              )

            }
          </Box>
        )
          : <div></div>}
    </div>
  );
};

export default About;
