import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { archiveData } from "../../data/archiveData";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { deletePosts, setPosts, setSelectedPost } from "../../features/archive/archiveSlice";
import { useGetPostsMutation, useDeletePostsMutation } from "../../features/archive/archiveApiSlice";
import ShowPosts from "../../components/ShowPosts";
import { toast } from "react-toastify";
import AddPost from "../../components/EditPost";

const Archive = () => {
  const [selectionModel, setSelectionModel] = React.useState([]); // holds all the selected post
  const [openShowPostModel, setOpenShowPostModel] = React.useState(false);
  const [openEditPostModel, setOpenEditPostModel] = React.useState(false);
 
  const dispatch = useDispatch();

  const [getPosts, {isLoading}] = useGetPostsMutation();
  const [delePosts] = useDeletePostsMutation();

  const posts = useSelector((state) => state.archive.posts);

  useEffect(() => {
    fetchAllPosts();
  }, [])

  // get all the posts from the server
  const fetchAllPosts = async () => {
    const posts = await getPosts().unwrap();
    dispatch(setPosts(posts.data))
    console.log(posts.data);
  }

  // Closes show post model which shows the post
  const closeShowPostModel = () => {
    setOpenShowPostModel(false);
    dispatch(setSelectedPost(null));
  }

  // Closes the edit post model which is used to edit post
  const closeEditPostModel = () => {
    setOpenEditPostModel(false);
    dispatch(setSelectedPost(null));
  }

  

  const deleteNewsPosts = async(postsIds) => {
    try {
      let postsToDelete = {
          news_ids: postsIds
        }
        
      const result = await delePosts(postsToDelete).unwrap();
      toast.success("Successfully delted " + selectionModel.length + "posts");
      // clearing the state
      setSelectionModel([]);
      return "success"
    }catch(err) {
      console.log(err);
      if(err.status === 404){
        toast.error("Please select atleast one post");
        return "error"
      }
    }
  }

  const columns = [
    { field: "id", headerName: "ID", filterable: false, minWidth: 80 },
    {
      field: "image_url",
      headerName: "Thumbnail",
      sortable: false,
      filterable: false,
      minWidth: 100,
      renderCell: (param, index) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <img src={param.value} style={{ height: "100%" }} />
        </Box>
      ),
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 180 },
    { field: "language", headerName: "Language", flex: 1, minWidth: 140 },
    { field: "created_at", headerName: "Created On", flex: 1, minWidth: 140},
    { field: "updated_at", headerName: "Updated On", flex: 1, minWidth: 140 },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      sortable: false,
      filterable: false,
      minWidth: 200,
      renderCell: (param) => (
        <Box width="100%">
          <IconButton sx={{ width: "40px" }} onClick={() => {
            dispatch(setSelectedPost(param.row))
            setOpenShowPostModel(true);
          }}>
            <VisibilityIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
              
          <IconButton sx={{ width: "40px" }}  onClick={() => {
            dispatch(setSelectedPost(param.row))
            setOpenEditPostModel(true);
          }}>
            <EditIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
          <IconButton sx={{ width: "40px" }} onClick={async() => {
            const selectedIDs = new Set([param.row.id]);
            let filteredPosts = posts.filter((x) => !selectedIDs.has(x.id));
            const result = await deleteNewsPosts([param.row.id]);
                if(result != "error"){
                  dispatch(setPosts(filteredPosts));
                  return;
                }
                return;
          }}>
            <DeleteIcon
              sx={{
                width: "20px",
                color: "#ba000d",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "delete",
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderHeader: () => {
        return (
          <IconButton
            onClick={async () => {
                if(selectionModel.length === 0) {
                  toast.error("Please select atleast 1 post to delete");
                  return;
                }
                const selectedIDs = new Set(selectionModel); 
                let filteredPosts = posts.filter((x) => !selectedIDs.has(x.id));
                const result = await deleteNewsPosts(selectionModel);
                if(result != "error"){
                  dispatch(setPosts(filteredPosts));
                  return;
                }
                return;
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
<>
    <ShowPosts isShowPostModalOpen={openShowPostModel} closeShowPostModel={closeShowPostModel} />
    <AddPost isEditPostModalOpen={openEditPostModel} closeEditPostModel={closeEditPostModel} /> 
    {/* TODO: Show spinner while data is loading */}
    {posts ? (
    <Box
      sx={{
        height: "80vh",
        backgroundColor: "#fff",
        borderRadius: "5px",
      }}
    >
        <DataGrid
          rows={posts}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
      />
    </Box>) : <Typography variant="h5">Loading...</Typography> }
</>
   
  );
};

export default Archive;
