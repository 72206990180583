import { buildCreateApi } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../app/services/auth/apiSlice";

export const faqsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createFaqs: builder.mutation({
            query: data => ({
                url: '/api/faqs/create_faqs',
                method: 'POST',
                body: { ...data }
            })
        }),
        deleteFaqs: builder.mutation({
            query: data => ({
                url: 'api/faqs/delete_faqs',
                method: 'DELETE',
                body: { ...data }
            })
        }),
         updateFaqs: builder.mutation({
            query: data => ({
                url: '/api/faqs/update_faqs',
                method: 'PUT',
                body: { ...data }
            })
        }),
        
         getFaqsByLanguage: builder.mutation({
            query: data => ({
                url: '/api/faqs/get_faqs_by_language',
                method: 'POST',
                body: { ...data }
            })
        }),
    })
})

export const {
    useCreateFaqsMutation,
    useDeleteFaqsMutation,
    useGetFaqsByLanguageMutation, 
    useUpdateFaqsMutation
} = faqsApiSlice;