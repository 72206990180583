import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import { authApi } from './services/auth/authService'
import { apiSlice } from './services/auth/apiSlice'
import archiveSlice from '../features/archive/archiveSlice'
import beneficiarySlice from '../features/beneficiary/beneficiarySlice'
import transactionSlice from '../features/transaction/transactionSlice'
import dashboardUserManagementSlice from '../features/dashboardUserManagement/dashboardUserManagementSlice'
import analyticsSlice from '../features/analytics/analyticsSlice'
import faqsSlice from "../features/faqs/faqsSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    archive: archiveSlice,
    beneficiary: beneficiarySlice,
    transaction: transactionSlice,
    dashboardUser: dashboardUserManagementSlice,
    analytics: analyticsSlice,
    faqs:faqsSlice,
    // [authApi.reducerPath]: authApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
})

export default store;
