import React, {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import Header from './components/common/Header'
import LoginScreen from './pages/Login/LoginScreen'
import RegisterScreen from './pages/RegisterScreen'
import Dashboard from './pages/dashboard/Dashboard'
import HomeScreen from './pages/HomeScreen'
import ProtectedRoute from './routing/ProtectedRoute'
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import { toast, ToastContainer } from 'react-toastify'
;
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { Button, ThemeProvider } from '@mui/material'
import { Login } from '@mui/icons-material';
import { theme } from "./theme";

function App() {

  const { notifications, clear, markAllAsRead, markAsRead } = useNotificationCenter();
  const { loading, userInfo, error } = useSelector((state) => state.auth);

  return (
    <Router>
      <ToastContainer  autoClose={2000}/>
      <main className='container'>
        <ThemeProvider theme={theme}>
        {userInfo ? <Dashboard /> : <LoginScreen />}
        </ThemeProvider>
        {/* <Dashboard /> */}
      </main>
    </Router>
  )
}

export default App
