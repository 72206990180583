import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  allTransactions: null,
  pendingTransactions: null,
  acceptedTransactions: null,
  rejectedTransactions: null,
  selectedPendingTransaction: null,
  error: null,
  success: false,
};

const transactionSlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    setTransactions: (state, { payload }) => {
      state.allTransactions = payload;
    },
    setSelectedTransaction: (state, { payload }) => {
      state.selectedPendingTransaction = payload;
    },
    setPendingTransaction: (state, { payload }) => {
      state.pendingTransactions = payload;
    },
    setAcceptedTransaction: (state, { payload }) => {
      state.acceptedTransactions = payload;
    },
    setRejectedTransaction: (state, { payload }) => {
      state.rejectedTransactions = payload;
    },
  },
});

export const { setTransactions, setSelectedTransaction, setPendingTransaction, setAcceptedTransaction, setRejectedTransaction } =
  transactionSlice.actions;

export default transactionSlice.reducer;

