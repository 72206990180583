import { apiSlice } from "../../app/services/auth/apiSlice";

export const dashboardUserManagementApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllDashboardUsers: builder.mutation({
            query: () => ({
                url: '/api/admin/get_all_dashboard_users',
                method: 'POST',
            })
        }),
        addDashboardUser: builder.mutation({
            query: data => ({
                url: '/api/admin/create_dashboard_user',
                method: 'POST',
                body: { ...data }
            })
        }),
        updateDashboardUserPassword: builder.mutation({
            query: data => ({
                url: '/api/admin/update_dashboard_password',
                method: 'PUT',
                body: { ...data }
            })
        }),
        updateDashboardUserDetails: builder.mutation({
            query: data => ({
                url: '/api/admin/update_dashboard_user_detail',
                method: 'PUT',
                body: { ...data }
            })
        }),
        deleteDashboardUser: builder.mutation({
            query: data => ({
                url: '/api/admin/delete_dashboard_user',
                method: 'DELETE',
                body: { ...data }
            })
        }),

    })
})

export const {
    useUpdateDashboardUserPasswordMutation,
    useUpdateDashboardUserDetailsMutation,
    useGetAllDashboardUsersMutation,
    useDeleteDashboardUserMutation,
    useAddDashboardUserMutation
} = dashboardUserManagementApiSlice