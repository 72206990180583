import { Grid } from "@mui/material";
import React from "react";
import ChangePassword from "../../components/DashboardUserManagement/ChangePassword";
import DashboardUserDetailUpdate from "../../components/DashboardUserManagement/DashboardUserDetailUpdate";

const Profile = () => {

  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <DashboardUserDetailUpdate />
      </Grid>
      <Grid item md={6} xs={12}>
        <ChangePassword />
      </Grid>
    </Grid>
  );
};

export default Profile;
