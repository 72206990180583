import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  Typography,
  Button,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { useUpdateDashboardUserPasswordMutation } from "../../features/dashboardUserManagement/dashboardUserManagementApiSlice";

const ChangePassword = () => {
  // Get the currently logged in admin
  // const loggedInUser = useSelector((state) => state.auth.userInfo);
  const selectedDashboardUser = useSelector((state) => state.dashboardUser.selectedDashboardUser);
  const [updatePassword] = useUpdateDashboardUserPasswordMutation();

  // use this state to toggle the password inputs
  const [showPassword, setShowPassword] = React.useState({
    // oldPasswordToggle: false,
    newPasswordToggle: false,
    confirmPasswordToggle: false,
  });

  // Values of the form fields
  const [passwordUpdateForm, setPasswordUpdateForm] = React.useState({
    // oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // use to store the errors for the field
  const [passwordErrors, setPasswordErrors] = React.useState({
    // oldPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const handlePasswordChange = (e) => {
    // Set the password fields value
    setPasswordUpdateForm((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });

    // On input change remove the erorr
    setPasswordErrors((prevValue) => {
      return {
        ...prevValue,
        [e.target.name + "Error"]: "",
      };
    });
  };

  // Toggle the password input fields
  const handleClickShowPassword = (toggleName) => {
    setShowPassword((prevValue) => {
      return {
        ...prevValue,
        [toggleName]: !showPassword[toggleName],
      };
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //   Use this function to set error for the password fields
  const handleSetError = (field, error) => {
    setPasswordErrors((prevValue) => {
      return {
        ...prevValue,
        [field]: error,
      };
    });
  };

  const resetPasswordForm = () => {
    setPasswordUpdateForm({
      // oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const validate = () => {
    let error = false;
    // if (passwordUpdateForm.oldPassword.length < 8) {
    //   error = true;
    //   handleSetError("oldPasswordError", "minimum 8 characters required");
    // }

    if (passwordUpdateForm.newPassword.length < 8) {
      error = true;
      handleSetError("newPasswordError", "minimum 8 characters required");
    }

    if (passwordUpdateForm.newPassword !== passwordUpdateForm.confirmPassword) {
      error = true;
      handleSetError(
        "confirmPasswordError",
        "new password and confirm password must match"
      );
    }

    if (error) {
      return "error";
    } else {
      return "success";
    }
  };

  const handleSubmit = async () => {
    // If there error then exit the function
    if (validate() === "error") {
      return;
    }

    console.log(passwordUpdateForm);

    try {
      // Add email to the payload object
      passwordUpdateForm.id = selectedDashboardUser.id;
      console.log(passwordUpdateForm);
      let result = await updatePassword(passwordUpdateForm).unwrap();
      toast.success(result?.message);
      resetPasswordForm();
    } catch (err) {
      console.log(err);
      if (err.status === 404) {
        toast.error(err.data?.message);
        return;
      }
      if(err.status === 403){
        toast.error(err.data?.message);
        return;
      }

      toast.error("Internal Server Error")
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "5px",
        p: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={40}
          >
            <Typography variant="h5">Change Password</Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
            <OutlinedInput
              id="oldPassword"
              type={showPassword.oldPasswordToggle ? "text" : "password"}
              name="oldPassword"
              label="Old Password"
              error={passwordErrors.oldPasswordError ? true : false}
              onChange={handlePasswordChange}
              value={passwordUpdateForm.oldPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("oldPasswordToggle")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.oldPasswordToggle ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <p className="error-text" style={{ marginBottom: "-5px" }}>
              {passwordErrors?.oldPasswordError
                ? passwordErrors.oldPasswordError
                : ""}
            </p>
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="newPassword">New Password</InputLabel>
            <OutlinedInput
              id="newPassword"
              type={showPassword.newPasswordToggle ? "text" : "password"}
              name="newPassword"
              label="New Password"
              error={passwordErrors.newPasswordError ? true : false}
              onChange={handlePasswordChange}
              value={passwordUpdateForm.newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("newPasswordToggle")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.newPasswordToggle ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <p className="error-text" style={{ marginBottom: "-5px" }}>
              {passwordErrors?.newPasswordError
                ? passwordErrors.newPasswordError
                : ""}
            </p>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="newPassword">Confirm Password</InputLabel>
            <OutlinedInput
              id="confirmPassword"
              type={showPassword.confirmPasswordToggle ? "text" : "password"}
              name="confirmPassword"
              label="Confirm Password"
              error={passwordErrors.confirmPasswordError ? true : false}
              onChange={handlePasswordChange}
              value={passwordUpdateForm.confirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword("confirmPasswordToggle")
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.confirmPasswordToggle ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <p className="error-text" style={{ marginBottom: "-5px" }}>
              {passwordErrors?.confirmPasswordError
                ? passwordErrors.confirmPasswordError
                : ""}
            </p>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={handleSubmit}>
            Change
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePassword;
