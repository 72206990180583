import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  analytics: [],
  error: null,
  success: false,
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalytics: (state, { payload }) => {
      state.analytics = payload;
    },
  },
});

export const { setAnalytics } =
  analyticsSlice.actions;

export default analyticsSlice.reducer;

