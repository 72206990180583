import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  faqsList: [],
  selectedFaqs:null,
  error: null,
  success: false,
}

const faqsSlice = createSlice({
  name: 'faqs', 
  initialState,
  reducers: {
    setFaqs: (state, {payload}) => {
      state.faqsList = payload
    },
    setSelectedFaqs: (state, {payload}) => {
      state.selectedFaqs = payload
    }
  },
})

export const { setFaqs, setSelectedFaqs } = faqsSlice.actions

export default faqsSlice.reducer;
