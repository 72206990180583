import { apiSlice } from "../../app/services/auth/apiSlice";

export const archiveApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPosts: builder.mutation({
            query: () => ({
                url: '/api/news/get_all_news',
                method: 'POST'
            })
        }),
        updatePost: builder.mutation({
            query: data => ({
                url: '/api/news/update_news',
                method: 'PUT',
                body: { ...data }
            })
        }),
        deletePosts: builder.mutation({
            query: data => ({
                url: '/api/news/delete_news',
                method: 'DELETE',
                body: { ...data }
            })
        }),
    })
})

export const {
    useGetPostsMutation,
    useDeletePostsMutation,
    useUpdatePostMutation
} = archiveApiSlice

