import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { useAddDashboardUserMutation } from "../../features/dashboardUserManagement/dashboardUserManagementApiSlice";
import { toast } from "react-toastify";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "",
};

const EMAILREGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const DashboardUserSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Too Short!").required("Required"),
  lastName: Yup.string().min(2, "Too Short!").required("Required"),
  email: Yup.string()
    .matches(EMAILREGEX, "Enter a valid email")
    .required("Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .required("Required"),
  role: Yup.string()
    .required("Required"),
});

const AddDashboardUser = () => {
  const [showPassword, setShowPassword] = React.useState({
    // oldPasswordToggle: false,
    newPasswordToggle: false,
    confirmPasswordToggle: false,
  });

  const[addDashboardUser] = useAddDashboardUserMutation();

  const handlePasswordChange = (e) => {
    // Set the password fields value
    // setPasswordUpdateForm((prevValue) => {
    //   return {
    //     ...prevValue,
    //     [e.target.name]: e.target.value,
    //   };
    // });
  };

  // Toggle the password input fields
  const handleClickShowPassword = (toggleName) => {
    setShowPassword((prevValue) => {
      return {
        ...prevValue,
        [toggleName]: !showPassword[toggleName],
      };
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      let result = await addDashboardUser(values).unwrap();
      toast.success("Success"); 
      resetForm();
      console.log(result);
    }catch(err) {
      console.log(err);
      if(err?.status === 409){
        toast.error(err?.data?.message);
        return;
      }
      toast.error("Internal server error");
    }
  };

  return (
    <Box>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={(DashboardUserSchema)}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <TextField
                  id="firstName"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  error={!!touched.firstName && !!errors.firstName}
                />
                <p className="error-text" style={{ marginBottom: "-5px" }}>
                  {touched.firstName && errors.firstName}
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="lastName"
                  variant="outlined"
                  label="Last Name"
                  name="lastName"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  error={!!touched.lastName && !!errors.lastName}
                />
                <p className="error-text" style={{ marginBottom: "-5px" }}>
                  {touched.lastName && errors.lastName}
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="emailID"
                  variant="outlined"
                  label="Email ID"
                  name="email"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  error={!!touched.email && !!errors.email}
                />
                <p className="error-text" style={{ marginBottom: "-5px" }}>
                  {touched.email && errors.email}
                </p>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="newPassword">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={
                      showPassword.confirmPasswordToggle ? "text" : "password"
                    }
                    name="password"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    error={!!touched.password && !!errors.password}
                    // value={passwordUpdateForm.confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("confirmPasswordToggle")
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.confirmPasswordToggle ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <p className="error-text" style={{ marginBottom: "-5px" }}>
                    {touched.password && errors.password}
                  </p>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="role-select"
                    name="role"
                    label="Role"
                    value={values.role}
                    onChange={handleChange}
                    error={!!touched.role && !!errors.role}
                  >
                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                    <MenuItem value={"SUPERADMIN"}>Super Admin</MenuItem>
                  </Select>
                  <p className="error-text" style={{ marginBottom: "-5px" }}>
                    {touched.role && errors.role}
                  </p>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button variant="outlined" type="submit">Add User</Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddDashboardUser;
