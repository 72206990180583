import React from "react";
import { Typography } from "@mui/material";

const ApplicantDetail = ({ heading, text }) => {
  return (
    <>
      <Typography variant="p" component="p" style={{ fontSize: "17px", margin: "2px 0" }}>
        <span style={{ fontWeight: 600 }}>{heading}</span>: {`${text}`}
      </Typography>
      <br />
    </>
  );
};

export default ApplicantDetail;
