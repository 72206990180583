import { createSlice } from "@reduxjs/toolkit";
import { beneficiaryApiSlice } from "./beneficiaryApiSlice";

const initialState = {
  loading: false,
  members: [],
  deletedMembers: [],
  selectedMembers: null,
  selectedPendingTransaction: null,
  error: null,
  success: false,
};

const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    setMembers: (state, { payload }) => {
      state.members = payload;
    },
    setSelectedMember: (state, { payload }) => {
      state.selectedMembers = payload;
    },
    setDeletedMembers: (state, { payload }) => {
      state.deletedMembers = payload;
    },
    setPendingTransaction: (state, {payload}) => {
      state.selectedPendingTransaction = payload;
    }
  },
});

export const { setMembers, setSelectedMember, setDeletedMembers, getPendingRequestsToApprove, setPendingTransaction } =
  beneficiarySlice.actions;

export default beneficiarySlice.reducer;

