import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  ListItem,
  withStyles,
  TextField,
} from "@mui/material";
import { usePublishArticleMutation, usePublishEnglishArticleMutation, usePublishHindiArticleMutation, usePublishMarathiArticleMutation } from "../../features/publisher/publisherApiSlice";
import { uploadFeaturedImage } from "../../api/awsS3.api";
import axios from 'axios';

import JoditEditor from "jodit-react";
import "./publisher.css";
import { padding } from "@mui/system";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setPosts, setSelectedPost } from "../../features/archive/archiveSlice";
import { useUpdatePostMutation } from "../../features/archive/archiveApiSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {};

const Publisher = ({ closeEditPostModel }) => {
  const post = useSelector((state) => state.archive.posts);
  const selectedPost = useSelector((state) => state.archive.selectedPost);

  const [featuredImage, setFeaturedImage] = useState(selectedPost?.image_url);
  const [previewImage, setPreviewImage] = useState(selectedPost?.image_url);
  const [title, setTitle] = useState(selectedPost?.title);
  const [content, setContent] = useState(selectedPost?.body);
  const [publishing, setPublishing] = useState(false);
  const [bodyPlainText, setBodyPlainText] = useState("");
  const [language, setLanguage] = React.useState(selectedPost?.language);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const [publishArticle, { isLoading }] = usePublishArticleMutation();

  const [updatePost] = useUpdatePostMutation();

  const editor = useRef(null);

  const dispatch = useDispatch();

  // TODO: set size limit
  const onImageChange = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setFeaturedImage(e.target.files[0]);
    // console.log(featuredImage);
  };

  const validateArticle = () => {
    let error = false;
    if (featuredImage == null) {
      toast.error("Please add a featured image");
      error = true;
    }

    if (title.length < 3) {
      toast.error("Title should be greater then 3 characters");
      error = true;
    }

    return error;
  };

  const handlePublishArticle = async () => {
    let isUpdatePost = false;

    // Will ge true if there was an error
    if (validateArticle()) {
      return;
    }


    if(language === undefined || language === "" || language === "selectLanguage"){
      toast.error("Please select a language");
      return;
    }

    // If the post is selected in redux store then set the update post to true
    if (selectedPost) {
      isUpdatePost = true;
    }

   

    try {
      // Upload image to s3 bucket
      toast.info("Publishing Arcticle");
      setPublishing(true);
      let image;
      if (!!featuredImage["name"]) {
        console.log("Uploading featured image");
        let temp_image = await uploadFeaturedImage(featuredImage)// if there is name property in the object then upload the image
        image = temp_image?.data?.image_location[0];
      } else {
        image = selectedPost.image_url; // if there is no name property then it mean there is url so get the selected posts url
      }
      // const featuredImageLocation = image.location; //S3 file location

      const reqBody = {
        image_url: image,
        title: title,
        body: content,
        plainText: bodyPlainText,
        language: language
      };

      let result;

      if (isUpdatePost) {
        reqBody.news_id = selectedPost.id; //add currently selected posts id to the req's object
        result = await updatePost(reqBody).unwrap();
      } else {
        result = await publishArticle(reqBody).unwrap();
      }

      toast.success("Successfully published article");
      setPublishing(false);
      // Resetting the content on the screen

      if (isUpdatePost) {
        var selectedPostIndex = post
          .map(function (x) {
            return x.id;
          })
          .indexOf(selectedPost.id);
        // set the updated post in the redux store

        let createdAtUTCDate = new Date(result.data[0].created_at);
        let updatedAtUTCDate = new Date(result.data[0].updated_at);
        let createdAtDate =
          createdAtUTCDate.getDate() +
          "/" +
          createdAtUTCDate.getMonth() +
          "/" +
          createdAtUTCDate.getFullYear();
        let updatedAtDate =
          updatedAtUTCDate.getDate() +
          "/" +
          updatedAtUTCDate.getMonth() +
          "/" +
          updatedAtUTCDate.getFullYear();

        let currentPost = {
          id: result.data[0].id,
          title: result.data[0].title,
          body: result.data[0].body,
          image_url: result.data[0].image_url,
          language: result.data[0].language,
          created_at: createdAtDate.toString(),
          updated_at: updatedAtDate.toString(),
        };

        // assign the posts from the redux store to the temp variables
        let updatedPost = JSON.parse(JSON.stringify(post));

        for (const key in updatedPost[selectedPostIndex]) {
          updatedPost[selectedPostIndex][key] = currentPost[key];
        }

        // Set the post in redux store to updated post
        dispatch(setPosts(updatedPost));
        closeEditPostModel();
      }
      // Clear out the fields
      setContent("");
      setTitle("");
      setFeaturedImage("");
      setPreviewImage("");
      dispatch(setSelectedPost(""));
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.message);
      setPublishing(false);
    }
  };

  const handleBodyChange = (newContent) => {
    let plainText = newContent.replace(/<[^>]+>/g, "");
    setBodyPlainText(plainText);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ margin: "12px 0 12px 0" }}
        component="label"
      >
        {featuredImage ? "Change Featured Image" : "Add Featured Image"}
        <input hidden accept="image/*" onChange={onImageChange} type="file" />
      </Button>
      {previewImage ? (
        <Box>
          <Typography variant="paragraph" fontSize={15}>
            Image preview
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              backgroundColor: "#fff",
              borderRadius: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "6px 0 12px 0",
              padding: "5px 0",
            }}
          >
            <img src={previewImage} alt="Preview Image" height="100%" />
          </Box>
        </Box>
      ) : null}
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h5" sx={{ margin: "5px 0 0 0" }}>
          Title
        </Typography>
        <TextField
          id="title"
          name="title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter title"
          sx={{
            margin: "5px 0 20px 0",
          }}
        />
        <Typography variant="h5" sx={{ margin: "0 0 5px 0" }}>
          Body
        </Typography>
        <JoditEditor
          ref={editor}
          value={content}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => {
            handleBodyChange(newContent);
          }}
        />

        <FormControl sx={{width: "200px", marginTop: "20px"}}>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="language-select"
            value={language}
            label="Language"
            onChange={handleChange}
          >
            <MenuItem value={"selectLanguage"} disabled>Select Language</MenuItem>
            <MenuItem value={"english"}>English</MenuItem>
            <MenuItem value={"hindi"}>Hindi</MenuItem>
            <MenuItem value={"marathi"}>Marathi</MenuItem>
          </Select>
        </FormControl>
        <br />

        <Button
          variant="contained"
          sx={{ margin: "12px 0 6px 0" }}
          onClick={handlePublishArticle}
          disabled={publishing ? true : false}
        >
          {selectedPost ? "Update" : "Post"}
        </Button>
      </Box>
    </div>
  );
};

export default Publisher;
