export const awsS3Config = {
    digitalOceanSpaces: 'https://mysaathi.sgp1.digitaloceanspaces.com',
    bucketName: 'mysaathi',

}

export const SERVERLINK = "https://saathiserver.yashchoprafoundation.org"

// export const SERVERLINK = "https://saathidev.anujnirmal.com";
// export const SERVERLINK = "http://localhost:5001";

// https://saathidev.anujnirmal.com
// export const dev = "http://localhost:8080";
// https://anujnirmal.com
// https://saathiserver.anujnirmal.com/
