import {
  Button
} from "@mui/material";
import React, {  useState } from "react";

import EducationPendingRequests from "./EducationPendingRequests";
import EducationAcceptedRequested from "./EducationAcceptedRequested";
import EducationRejectedRequests from "./EducationRejectedRequests";

const Education = () => {
  const [activeMenu, setActiveMenu] = useState("PENDING");
  
  const handleMenuChange = (menuName) => {
    setActiveMenu(menuName);
  }

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <Button
          variant={activeMenu === "PENDING" ? "contained" : "outlined"}
          sx={{ margin: "0 8px 0 0" }}
          onClick={() => handleMenuChange("PENDING")}
        >
          Pending
        </Button>
        <Button
          variant={activeMenu === "ACCEPTED" ? "contained" : "outlined"}
          sx={{ margin: "0 8px" }}
          onClick={() => handleMenuChange("ACCEPTED")}
        >
          Accepted
        </Button>
        <Button
          variant={activeMenu === "REJECTED" ? "contained" : "outlined"}
          sx={{ margin: "0 8px" }}
          onClick={() => handleMenuChange("REJECTED")}
        >
          Rejected
        </Button>
      </div>
      {activeMenu === "PENDING" && <EducationPendingRequests />}
      {activeMenu === "ACCEPTED" && <EducationAcceptedRequested />}
      {activeMenu === "REJECTED" && <EducationRejectedRequests />}
    </>
  );
};

export default Education;
