import { apiSlice } from "../../app/services/auth/apiSlice";

export const AnalyticsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllAnalyticsData: builder.mutation({
            query: () => ({
                url: '/api/analytics/get_all_analytics',
                method: 'POST'
            })
        }),
    })
})

export const {
    useGetAllAnalyticsDataMutation,
} = AnalyticsApiSlice