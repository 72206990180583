import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useCreateFaqsMutation,
  useDeleteFaqsMutation,
  useUpdateFaqsMutation,
} from "../../features/faqs/faqsApiSlice";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { SERVERLINK } from "../../config/config";

const Faqs = () => {
  const [isCreateFaq, setIsCreateFaq] = useState(false);

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [data, setData] = useState({ answer: "", question: "", language: "" });
  const [faqData, setFaqData] = useState(null);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [addFaqs] = useCreateFaqsMutation();
  const [updateFaqs] = useUpdateFaqsMutation();
  const [deleteFaqs] = useDeleteFaqsMutation();

  const [beneficiaryMenu, setBeneficiary] = useState({
    members: true,
    addMember: false,
    deletedMember: false,
    showMemberDetails: false,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", 
    bgcolor: "background.paper",
    border: "0",
    borderRadius: "5px",
    boxShadow: 24,
    overflow: "scroll",
    pt: 2,
    px: 4,
    pb: 3,
    m: "20px 0",
  };

  const matches = useMediaQuery('(max-width:750px)');

  const columns = [
    { field: "id", headerName: " ID" },

    {
      field: "question",
      headerName: "questions",
      minWidth: 200,
    },
    {
      field: "answer",
      headerName: " Answers",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "language",
      headerName: "Language",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 180,
      filterable: false,
      sortable: false,
      renderCell: (param) => (
        <Box width="100%">
          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              setSelectedFaq(param.row);
              setViewModel(true);
            }}
          >
            <VisibilityIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>

          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              setSelectedFaq(param.row);

              setEditModel(true);
            }}
          >
            <EditIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
          <IconButton
            sx={{ width: "40px" }}
            onClick={ () => {
             deleteOnClick(param)
            }}
          >
            <DeleteIcon
              sx={{
                width: "20px",
                color: "#ba000d",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  function showDataBasedOnLanguages(language) {
   
    axios
      .post(`${SERVERLINK}/api/faqs/get_faqs_by_language`, {
        language: language.toLowerCase(),
      })
      .then((response) => {
        setFaqData(response.data.data);
        toast.success("Language Selected !")

      })
      .catch((error) => {
        toast.error("Something went wrong !");
      });
  }

  // This function is used for adding new FAQ into your list , and it is being called in the  Model FAQ
  const addOnClick= async function(){
                    try {
                      
                      const result = await addFaqs({
                        question: data.question,
                        answer: data.answer,
                        language: data.language,
                      }).unwrap();
                      // console.log(faqData);
                      // console.log(result);
                      // return ;
                      toast.success("Successfully FAQ added !");
                      setIsCreateFaq(false);
                      setData({ answer: "", question: "", language: "" });
                      
                      if(faqData === null){
                        setFaqData([result.data[0]])
                        return;
                      }

                      setFaqData((prevValue) => {
                        return [
                          ...prevValue,
                          result.data[0]
                        ]
                      })
                    } catch {
                      toast.error("Something went wrong !");
                    }
    
                  }
                  //This function  updates the seletced row from the grid 
  const updateOnClick= async function(){
   
                    try {
                      const result = await updateFaqs({
                        faqs_id: selectedFaq.id,
                        question: selectedFaq.question,
                        answer: selectedFaq.answer,
                        language: selectedFaq.language,
                      }).unwrap();
                      const copyOfFaqData = faqData;
                      const updatedData = copyOfFaqData.map((faq) =>
                        faq.id === result.data[0].id
                          ? {
                              ...faq,
                              question: result.data[0].question,
                              answer: result.data[0].answer,
                              language: result.data[0].language,
                            }
                          : faq
                      );

                      setFaqData(updatedData);
                      toast.success("Successfully Edited  !");
                      setSelectedFaq({
                        answer: "",
                        question: "",
                        language: "",
                      });
                      setEditModel(false);
                    } catch {
                      toast.error("Something went wrong !");
                    }
                  
                }
  
  const deleteOnClick= async function(param){
 try {
                const data = faqData;
                const val = await deleteFaqs({ faqs_ids: [param.row.id] });

                
                const res = faqData.filter((x) => {
                  return x.id !== param.row.id;
                });
                  toast.success("Successfully Deleted !");

                setFaqData(res);
              } catch {
                toast.error("something went wrong!"); 
              }
  }

  return (
    <>
      {!beneficiaryMenu.showMemberDetails && (
        <Box  className="beneficiary-button-container">
          <Box sx={{ display: "flex" }}>
            <Button
              variant={"outlined"}
              
              className="beneficiary-submenu-button"
              id="addMember"
              
              onClick={() => setIsCreateFaq(true)}
            >
              Add FAQs
            </Button>
            <Box paddingLeft={2}>
              <FormControl sx={{ width: "200px" }}>
                <InputLabel  id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="language-select"
                  value={data.language}
                  label="Language"
                  onChange={(e) =>
                    setData({ ...data, language: e.target.value })
                  }
                  
                >
                  <MenuItem value={"selectLanguage"} disabled>
                    Select Language
                  </MenuItem>
                   <MenuItem onClick={() => showDataBasedOnLanguages("English")} value={"english"}>
                     
                        English
                      
                    </MenuItem>

                     <MenuItem onClick={() => showDataBasedOnLanguages("Hindi")} value={"hindi"}>
                     
                        Hindi
                      
                    </MenuItem>
                     <MenuItem onClick={() => showDataBasedOnLanguages("Marathi")} value={"marathi"}>
                     
                        Marathi
                      
                    </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

{/* Model Create FAQ  */}
          <Modal
            open={isCreateFaq}
            onClose={() => setIsCreateFaq(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{
              width: matches ? "95vw" : "50vw"
            }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <Typography variant="h5">Add FAQ</Typography>
                <Button
                  variant="contained"
                  onClick={() => setIsCreateFaq(false)}
                >
                  Close
                </Button>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Question"
                    value={data.question}
                    onChange={(e) =>
                      setData({ ...data, question: e.target.value })
                    }
                    label="Question"
                    variant="outlined"
                    name="Question"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Answer"
                    value={data.answer}
                    onChange={(e) =>
                      setData({ ...data, answer: e.target.value })
                    }
                    label="Answer"
                    variant="outlined"
                    name="Answer"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>

              <FormControl sx={{ width: "200px", marginTop: "20px" }}>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="language-select"
                  value={data.language}
                  label="Language"
                  onChange={(e) =>
                    setData({ ...data, language: e.target.value })
                  }
                >
                  <MenuItem value={"selectLanguage"} disabled>
                    Select Language
                  </MenuItem>
                  <MenuItem value={"english"}>English</MenuItem>
                  <MenuItem value={"hindi"}>Hindi</MenuItem>
                  <MenuItem value={"marathi"}>Marathi</MenuItem>
                </Select>
              </FormControl>
              <Grid pt={2} item xs={12}>
                <Button
                  variant="outlined"
                  onClick={()=>addOnClick()}
                >
                  Save
                </Button>
              </Grid>
            </Box>
          </Modal>
          {/*  Model View FAQ */}
          <Modal
            open={viewModel}
            onClose={() => setViewModel(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{
              width: matches ? "95vw" : "50vw"
            }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "50px",
                }}
              >
                <Typography variant="h5">View FAQ</Typography>
                <Button variant="contained" onClick={() => setViewModel(false)}>
                  Close
                </Button>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Question"
                    value={selectedFaq?.question}
                    onChange={(e) =>
                      setData({ ...data, question: e.target.value })
                    }
                    label="Question"
                    variant="outlined"
                    name="Question"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Answer"
                    value={selectedFaq?.answer}
                    onChange={(e) =>
                      setData({ ...data, answer: e.target.value })
                    }
                    label="Answer"
                    variant="outlined"
                    name="Answer"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Language"
                    value={selectedFaq?.language}
                    label="language"
                    variant="outlined"
                    name="language"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
            </Box>
          </Modal>
         {/* Model Edit FAQ */}
          <Modal
            open={editModel}
            onClose={() => setEditModel(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{
              width: matches ? "95vw" : "50vw"
            }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "50px",
                }}
              >
                <Typography variant="h5">Edit FAQ</Typography>
                <Button variant="contained" onClick={() => setEditModel(false)}>
                  Close
                </Button>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Question"
                    value={selectedFaq?.question}
                    onChange={(e) =>
                      setSelectedFaq((prevValue) => {
                        return {
                          ...prevValue,
                          question: e.target.value,
                        };
                      })
                    }
                    label="Question"
                    variant="outlined"
                    name="Question"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Answer"
                    value={selectedFaq?.answer}
                    onChange={(e) =>
                      setSelectedFaq((prevValue) => {
                        return {
                          ...prevValue,
                          answer: e.target.value,
                        };
                      })
                    }
                    label="Answer"
                    variant="outlined"
                    name="Answer"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12}>
                  <TextField
                    id="Language"
                    value={selectedFaq?.language}
                    label="language"
                    variant="outlined"
                    name="language"
                    size="small"
                    multiline
                    fullWidth
                    disabled
                  />
                </Grid>
              </Box>
              <Grid item xs={12} paddingTop={3}>
                <Button
                  variant="outlined"
                  onClick={()=>updateOnClick()}
                >
                  Update
                </Button>
              </Grid>
            </Box>
          </Modal>
        </Box>
      )}
      { (
        <Box
          sx={{
            height: "70vh",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
          {faqData ? (
            <DataGrid
            rows={faqData}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            density="compact"
          />
          ): <Typography      paddingTop={25}  textAlign={"center"}  flexGrow={1}> Loading.... </Typography>}
        </Box>
      )}
    </>
  );
};

export default Faqs;
