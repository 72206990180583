import { apiSlice } from "../../app/services/auth/apiSlice";

export const transactionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addTransaction: builder.mutation({
            query: data => ({
                url: '/api/transactions/create_transaction',
                method: 'POST',
                body: { ...data }
            })
        }),
        getAllPendingTransactions: builder.mutation({
            query: data => ({
                url: 'api/transactions/get_all_pending_transactions',
                method: 'POST',
                body: {...data}
            })
        }),
        getAllAcceptedTransactions: builder.mutation({
            query: data => ({
                url: '/api/transactions/get_all_accepted_transactions',
                method: 'POST',
                body: {...data}
            })
        }),
        getAllRejectedTransactions: builder.mutation({
            query: data => ({
                url: '/api/transactions/get_all_rejected_transactions',
                method: 'POST',
                body: {...data}
            })
        }),
        getTransactions: builder.mutation({
            query: () => ({
                url: '/api/transactions/get_transactions',
                method: 'POST',
            })
        }),
        acceptTransaction: builder.mutation({
            query: data => ({
                url: '/api/transactions/accept_pending_transaction',
                method: 'PUT',
                body: {...data}
            })
        }),
        rejectTransaction: builder.mutation({
            query: data => ({
                url: '/api/transactions/reject_pending_transaction',
                method: 'PUT',
                body: {...data}
            })
        }),
        acceptAmount: builder.mutation({
            query: data => ({
                url: '/api/transactions/accept_amount',
                method: 'PUT',
                body: {...data}
            })
        }),
       
        }),
})

export const {
    useAddTransactionMutation,
    useGetAllPendingTransactionsMutation,
    useGetAllAcceptedTransactionsMutation,
    useGetAllRejectedTransactionsMutation,
    useGetTransactionsMutation,
    useAcceptTransactionMutation,
    useRejectTransactionMutation,
    useAcceptAmountMutation,
} = transactionApiSlice