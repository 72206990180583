import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../features/auth/authActions";
import { useEffect } from "react";
import Spinner from "../../components/common/Spinner";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { display, margin, width } from "@mui/system";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Logo from "../../assets/images/logo.png";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "./login.css";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { setCredentials } from "../../features/auth/authSlice";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const initialValues = {
  email: "",
  password: "",
};

const LoginScreen = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  
  const navigate = useNavigate();

  const [login, {isLoading}] = useLoginMutation();
  const dispatch = useDispatch();

  // Use to toggle the password field from 'password' to 'text'
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    try {
      const userData = await login(values).unwrap();
      const userDataJson = JSON.stringify(userData);
      toast.success("Successfully Loggedin");
      let payload = { userData: userData, token: userData.access_token };
      dispatch(setCredentials(payload));
      localStorage.setItem('userInfo', userDataJson)
      localStorage.setItem('userToken', userData.access_token);
    }catch(err) {
      console.log(err);
      if(err.status === "FETCH_ERROR"){
        toast.error("Unable to connect to server")  
        return; 
      } 
      toast.error(err.data.message)    
    }
    
  };

  return (
    <div
      style={{
        height: "100vh",
        // width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          maxWidth: "400px",
          padding: "20px 0",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0 0 0",
            }}
          >
            <img
              src={Logo}
              alt="The yash raj chopra foundation"
              style={{
                width: "120px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom align="center">
              Saathi Admin
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={loginSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ width: "90%", margin: "0 auto" }}
                >
                  <Grid item xs={12}>
                    {/* Email Id */}
                    <FormControl
                      fullWidth
                      sx={{ margin: "10px 0" }}
                      variant="outlined"
                    >
                      <TextField
                        id="email"
                        label="Email Id"
                        variant="outlined"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        error={!!touched.email && !!errors.email}
                      />
                      <p className="error-text">
                        {touched.email && errors.email}
                      </p>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    {/* Password Field */}
                    <FormControl
                      fullWidth
                      sx={{ margin: "10px 0" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        label="Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        error={!!touched.password && !!errors.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <p className="error-text">
                        {touched.password && errors.password}
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {/* Login Button */}
                    <Box>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                        sx={{
                          width: "100px",
                          height: "40px",
                          margin: "13px 0",
                        }}
                      >
                        {loading ? <Spinner /> : "Login"}
                      </Button>
                    </Box>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default LoginScreen;
