import { Box, Button, IconButton, Modal, Divider, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPendingTransaction,
} from "../../features/beneficiary/beneficiarySlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewReceiptAndAccept from "../../components/Education/ViewReceiptAndAccept";
import { useGetAllPendingTransactionsMutation } from "../../features/transaction/transactionApiSlice";
import Spinner from "../../components/common/Spinner";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setSelectedTransaction } from "../../features/transaction/transactionSlice";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const EducationPendingRequests = () => {
  const [confirmationScreen, setConfirmationScreen] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [dateRange, setDateRange] = React.useState({
    startDate: "",
    endDate: "",
  });
  const [getAllData, setGetAllData] = useState(false);
  const [openShowReceiptModule, setOpenShowReceiptModule] =
    React.useState(false);

  const dispatch = useDispatch();
  const { pendingTransactions } = useSelector((state) => state.transaction);
  const [getAllPendingTransactions] = useGetAllPendingTransactionsMutation();

  const handleSearch = async () => {

    try {

      if(!getAllData){
        if(dateRange.startDate === "") return toast.error("Please select start date");
        if(dateRange.endDate === "") return toast.error("Please select end date");
        if(dateRange.startDate > dateRange.endDate) return toast.error("End date should be less than start date or equal")
      }

        const startDate = new Date(dateRange.startDate).toLocaleDateString();
        const endDate = new Date(dateRange.endDate).toLocaleDateString()
        
        const result = await getAllPendingTransactions({
          start_date: startDate, 
          end_date: endDate,
          get_all: getAllData
        }).unwrap();

        dispatch(setPendingTransaction(result?.data))

    } catch (error) {

        console.log(error);
        toast.error("Something went wrong");
        
    }
 
  };



  const transactionsColumn = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 160,},
    { field: "full_name", headerName: "name", flex: 1, minWidth: 200, valueGetter: (params) => {
      return params.row?.member?.full_name
    } },
    {
      field: "request_date",
      headerName: "Request Date",
      flex: 1,
      minWidth: 160,
      renderCell: (params) => {
        // console.log(params.row.receipts[0]?.upload_date);
        let UTCDate = new Date(params.row?.receipts[0]?.upload_date);
        let date =
          months[UTCDate.getMonth()] +
          " " +
          UTCDate.getDate() +
          ", " +
          UTCDate.getFullYear();
        return date;
      },
    },
    { field: "ycf_id", headerName: "YCF ID", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.member?.ycf_id
    } },
    // { field: "yearly_quota", headerName: "Total Limit", flex: 1, minWidth: 160, valueGetter: (params) => {
    //   return params.row?.member?.yearly_quota
    // }},
    { field: "bank_name", headerName: "Bank Name", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.member?.bank_detail[0]?.bank_name;
    }},
    { field: "bank_account_number", headerName: "Bank Account Number", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.member?.bank_detail[0]?.bank_account_number;
    }},
    { field: "ifsc_code", headerName: "IFSC Code", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.member?.bank_detail[0]?.ifsc_code;
    }},
    { field: "bank_branch_name", headerName: "Bank Branch Name", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.member?.bank_detail[0]?.bank_branch_name;
    }},
    { field: "children_name", headerName: "Children Name", flex: 1, minWidth: 160, valueGetter: (params) => {
      // return params.row?.member?.children[0]?.;
      let childName = "";
      for (let i = 0; i < params?.row?.member?.children?.length; i++) {
        if((params?.row?.member?.children?.length - 1) === i){
          childName += (i + 1) + ") " + params.row?.member?.children[i]?.child_name;
        }else {
          childName += (i + 1) + ") " + params.row?.member?.children[i]?.child_name + " / ";
        }
      }
      return childName;
    }},
    { field: "school_college_name", headerName: "School College Name", flex: 1, minWidth: 160, valueGetter: (params) => {
      let school_name = "";
      for (let i = 0; i < params?.row?.member?.children?.length; i++) {
        if((params?.row?.member?.children?.length - 1) === i){
          school_name += (i + 1) + ") " + params.row?.member?.children[i].school_college_name;
        }else {
          school_name += (i + 1) + ") " + params.row?.member?.children[i].school_college_name + " / ";
        }
      }
      return school_name;
    }},
    { field: "standard_grade", headerName: "Standard/Grade", flex: 1, minWidth: 160, valueGetter: (params) => {
      let standard_grade = "";
      for (let i = 0; i < params?.row?.member?.children?.length; i++) {
        if((params?.row?.member?.children?.length - 1) === i){
          standard_grade += (i + 1) + ") " + params.row?.member?.children[i]?.standard_grade;
        }else {
          standard_grade += (i + 1) + ") " + params.row?.member?.children[i]?.standard_grade + " / ";
        }
      }
      return standard_grade;
    }},
    { field: "document_links", headerName: "Document Links", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.receipts?.map((receipt) => receipt?.receipt_link); 
    }},
    // TODO: add redeemed column
    // {
    //   field: "redeemed",
    //   headerName: "Redeemed",
    //   flex: 1,
    //   renderCell: (param) => {
    //     return param.row.yearly_quota - param.row.balance;
    //   },
    // },
    { field: "balance", headerName: "Balance", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.member?.member_benefits[0]?.education_amount_balance
    }},
    { field: "requested_amount", headerName: "Requested Amount", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.amount_requested
    }},
    { field: "amount_updated", headerName: "Updated", flex: 1, minWidth: 160, valueGetter: (params) => {
      console.log(params.row);
      return params.row?.amount_updated === null ? "False" : params.row?.amount_updated === false ? "False" : "True"
    }},
    { field: "remarks", headerName: "Remarks", flex: 1, minWidth: 160, valueGetter: (params) => {
      return params.row?.remarks ? params.row?.remarks : '';
    }},
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 180,
      sortable:false,
      renderCell: (param) => (
        <Box>
          <IconButton
            onClick={() => {
              // Get the currently selected transaction using the filter method
              let singleTransaction = pendingTransactions.filter((x) => x?.id === param.row?.id);
              // set this selected transaction in the redux store
              dispatch(setSelectedTransaction(singleTransaction));
              // Open the model to continue the transactions
              setOpenShowReceiptModule(true);
              setConfirmationScreen(false);
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <Button
            onClick={() => {
              // Get the currently selected transaction using the filter method
              let singleTransaction = pendingTransactions.filter((x) => x?.id === param.row?.id);
              // set this selected transaction in the redux store
              dispatch(setSelectedTransaction(singleTransaction));
              // Open the model to continue the transactions
              setOpenShowReceiptModule(true);
              setConfirmationScreen(true);
            }}
          >
            Confirm
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Modal
        open={openShowReceiptModule}
        onClose={() => setOpenShowReceiptModule(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="member-details-transaction-model">
          <ViewReceiptAndAccept
            setOpenShowReceiptModule={setOpenShowReceiptModule}
            confirmationScreen={confirmationScreen}
            setCounter={setCounter}
            counter={counter}
          />
        </Box>
      </Modal>
      
     
        <Box
          sx={{ height: "717px", backgroundColor: "#fff", borderRadius: "5px" }}
        >
          <div  style={{
          display: "flex",
          marginLeft: 10,
        }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer  components={["DatePicker", "DatePicker"]}>
            <DemoContainer  components={["DatePicker"]}>
              <DatePicker
                label="Start Date"
                onChange={(e) =>
                  setDateRange((prevValue) => ({ ...prevValue, startDate: e }))
                }

              />
            </DemoContainer>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="End Date"
                onChange={(e) =>
                  setDateRange((prevValue) => ({ ...prevValue, endDate: e }))
                }
              />
            </DemoContainer>
          </DemoContainer>
        </LocalizationProvider>
       
        <Button
          variant="outlined"
          sx={{ marginTop: 2, marginLeft: 2 }}
          onClick={handleSearch}
        >
          Search
        </Button>
      
          </div>
          <FormGroup sx={{paddingLeft: "10px"}}>
            <FormControlLabel  control={<Checkbox onChange={(e) => setGetAllData(e.target.checked)} />} label="Get All" />
          </FormGroup>
        <Divider sx={{ marginTop: "0" }} />
        {pendingTransactions && (
          <DataGrid
            rows={pendingTransactions}
            columns={transactionsColumn}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            sx={{
              height: "600px"
            }}
            density="compact"
            slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
          />
        )}
          
        </Box>

    </Box>
  );
};

export default EducationPendingRequests;
